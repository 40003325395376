import React, { memo, useCallback, useState } from 'react';
import { List, Datagrid, TextField, NumberField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import OpenEditPage from './OpenEditPage';
import EditPage from './EditPage';

const styles = {
  center: {
    textAlign: 'center',
    width: 100,
  },
};

const AdvertisementList = ({ classes, ...props }) => {
  const [editIsOpen, changeEditIsOpen] = useState(false);

  const handleOpenEdit = useCallback(() => {
    changeEditIsOpen(true);
  }, []);

  const handleCloseEdit = useCallback(() => {
    changeEditIsOpen(false);
  }, []);

  return (
    <>
      {!editIsOpen && (
        <List
          {...props}
          exporter={false}
          bulkActions={false}
          actions={<OpenEditPage openEdit={handleOpenEdit} />}
        >
          <Datagrid>
            <NumberField
              source="order"
              sortable={false}
              className={classes.center}
              headerClassName={classes.center}
            />
            <TextField source="name" sortable={false} />
          </Datagrid>
        </List>
      )}
      {editIsOpen && <EditPage closeEdit={handleCloseEdit} />}
    </>
  );
};

export default memo(withStyles(styles)(AdvertisementList));
