import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  container: {
    display: 'flex',
  },
  text: {
    fontSize: '10px',
    textAlign: 'center',
  },
  number: {
    textAlign: 'center',
    padding: '0 3px',
  },
});

const SumCell = (props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.number}>{props.min}</div>
        <div className={classes.text}>min</div>
      </div>
      <div>:</div>
      <div>
        <div className={classes.number}>{props.sec}</div>
        <div className={classes.text}>sec</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SumCell);
