import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { SaveButton, Toolbar } from 'react-admin';
import { stylesToolbar } from '../stylesToolbar';

const styles = (theme) => ({
  ...stylesToolbar,
});

class ToolbarResetPassword extends Component {
  render() {
    const { classes, ...props } = this.props;
    return (
      <Toolbar {...props} className={classes.root}>
        <SaveButton label="Set up account" redirect="/login" icon={<span />} submitOnEnter />
      </Toolbar>
    );
  }
}

export default withStyles(styles)(ToolbarResetPassword);
