export default function toFindFileInObjectByString(o, s) {
  // eslint-disable-next-line no-param-reassign
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  // eslint-disable-next-line no-param-reassign
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  // eslint-disable-next-line no-plusplus
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (o && k in o) {
      // eslint-disable-next-line no-param-reassign
      o = o[k];
    } else {
      return;
    }
  }
  // eslint-disable-next-line consistent-return
  return o;
}
