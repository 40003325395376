import React, { memo, useState, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Button, SaveButton, CREATE } from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import dataProvider from '../../providers/dataProvider';
import AddProduct from './AddProduct';
import EditPageItem from './EditPageItem';

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const EditPage = ({ classes, closeEdit, advertisementProductsOuter }) => {
  const [advertisementProducts, changeAdvertisementProducts] = useState(advertisementProductsOuter);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = advertisementProducts[dragIndex];

      changeAdvertisementProducts(
        update(advertisementProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [advertisementProducts],
  );

  const updateOrder = useCallback(() => {
    changeAdvertisementProducts((old) => old.map((a, index) => ({ ...a, order: index + 1 })));
  }, []);

  const [isSaving, changeIsSaving] = useState(false);
  const handleSaveAdvertisement = useCallback(() => {
    changeIsSaving(true);
    dataProvider(CREATE, 'admin/advertisement', { data: { advertisement: advertisementProducts } })
      .then(() => {
        changeIsSaving(false);
        closeEdit();
      })
      .catch(() => {
        changeIsSaving(false);
      });
  }, [closeEdit, advertisementProducts]);

  return (
    <div onDragEnd={updateOrder}>
      <AddProduct
        advertisementProducts={advertisementProducts}
        changeAdvertisementProducts={changeAdvertisementProducts}
      />
      <DndProvider backend={HTML5Backend}>
        {advertisementProducts.map((item, index) => (
          <EditPageItem
            key={item.productId}
            item={item}
            changeArray={changeAdvertisementProducts}
            moveCard={moveCard}
            id={item.productId}
            index={index}
          />
        ))}
      </DndProvider>
      <div className={classes.buttonContainer}>
        <Button onClick={closeEdit}>
          <div>Cancel</div>
        </Button>
        <SaveButton onClick={handleSaveAdvertisement} disabled={isSaving} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    advertisementProductsOuter: state.admin.resources['admin/advertisement'].list.ids.map(
      (i) => state.admin.resources['admin/advertisement'].data[i],
    ),
  };
};

export default memo(connect(mapStateToProps)(withStyles(styles)(EditPage)));
