import React, { PureComponent } from 'react';
import {
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FileField,
  Datagrid,
  BooleanField,
  ArrayField,
  ShowController,
  ShowView,
  FunctionField,
} from 'react-admin';
import ImgField from '../../../components/ImgField/ImgField';

class ExercisesShow extends PureComponent {
  render() {
    return (
      <ShowController {...this.props}>
        {(controllerProps) => {
          const data = { ...controllerProps.record };
          data.type = data.type === 1;
          data.demoVideoMp4 = `https://stream.mux.com/${data.video?.muxPlaybackId}/low.mp4`;
          data.tutorialVideoMp4 = `https://stream.mux.com/${data.fullVideo?.muxPlaybackId}/low.mp4`;

          return (
            <ShowView {...this.props} {...controllerProps} record={data}>
              <TabbedShowLayout>
                <Tab label="Main information">
                  <ImgField source="picture" title="Picture" addLabel width="100" height="100" />
                  <TextField source="name" />
                  <RichTextField source="description" />
                  <FileField
                    target="_blank"
                    source="demoVideoMp4"
                    title="video.originalName"
                    label="Video"
                    addLabel
                  />
                  <FileField
                    target="_blank"
                    source="tutorialVideoMp4"
                    title="fullVideo.originalName"
                    label="Full Video"
                    addLabel
                  />
                  <BooleanField
                    source="forEachSide"
                    label="Ask user to switch sides and repeat exercise"
                  />
                  <BooleanField source="type" label="Use repetition counter instead of timer" />
                  <BooleanField source="doneButton" label="Hide done button in timer" />
                </Tab>
                <Tab label="Cue images">
                  <ArrayField source="cueImages" addLabel={false} style={{ minWidth: '100%' }}>
                    <Datagrid style={{ minWidth: '100%' }}>
                      <ImgField source="picture" title="Picture" addLabel width="50" height="50" />
                      <RichTextField source="description" />
                    </Datagrid>
                  </ArrayField>
                </Tab>
                <Tab label="On screen tips">
                  <RichTextField label="Tip 1" source="tips.0" />
                  <RichTextField label="Tip 2" source="tips.1" />
                  <RichTextField label="Tip 3" source="tips.2" />
                </Tab>
                <Tab label="Relation">
                  <ArrayField source="relation" addLabel={false}>
                    <Datagrid>
                      <FunctionField
                        label="Session Name (Program Name)"
                        render={(record) =>
                          `${record.session.name} (${record.session.program.name || '-'})`
                        }
                      />
                    </Datagrid>
                  </ArrayField>
                </Tab>
              </TabbedShowLayout>
            </ShowView>
          );
        }}
      </ShowController>
    );
  }
}

export default ExercisesShow;
