import React, { memo } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import AchievementList from './AchievementList';
import AchievementCreate from './AchievementCreate';

const AchievementManager = (props) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="title" gutterBottom>
          Achievement Manager
        </Typography>
        <AchievementList {...props} />
        <AchievementCreate {...props} />
      </CardContent>
    </Card>
  );
};

export default memo(AchievementManager);
