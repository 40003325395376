import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
import Session from 'supertokens-auth-react/recipe/session';
import { redirectToAuth } from 'supertokens-auth-react';
import dataProvider from './dataProvider';

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    await Session.signOut();
    localStorage.clear();
    return Promise.resolve({ redirectTo: '/auth/' });
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      await Session.signOut();
      localStorage.clear();
      return Promise.reject(new Error('Session expired'));
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return (await Session.doesSessionExist())
      ? Promise.resolve()
      : Promise.reject(new Error('Session does not exist'));
  }
  if (type === AUTH_GET_PERMISSIONS) {
    if (window.location.pathname === '/auth/') {
      return Promise.resolve('guest');
    }
    if (!(await Session.doesSessionExist())) {
      return redirectToAuth({ redirectBack: false });
    }
    if (!localStorage.getItem('user')) {
      await dataProvider('GET_ONE', `users/me`)
        .then((res) => {
          const user = res.data;
          if (user.role === 'User') {
            return Session.signOut();
          }
          localStorage.setItem('user', JSON.stringify(user));
          return Promise.resolve(user);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          if (error.response.status === 404) {
            // eslint-disable-next-line no-console
            console.error('User not found in the Praxis database');
          }
          return Session.signOut();
        });
    }

    const user = JSON.parse(localStorage.getItem('user'));
    return user && user.role ? Promise.resolve(user.role) : Promise.resolve('guest');
  }
  return Promise.reject(new Error('Unkown method'));
};
