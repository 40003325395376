import React, { PureComponent } from 'react';
import { Edit } from 'react-admin';
import EditCustomerForm from './EditFormCustomer';

class EditCustomer extends PureComponent {
  render() {
    return (
      <Edit {...this.props}>
        <EditCustomerForm />
      </Edit>
    );
  }
}

export default EditCustomer;
