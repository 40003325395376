import React, { PureComponent } from 'react';
import { Edit } from 'react-admin';
import { withStyles } from '@material-ui/core';
import SessionDetailForm from './SessionDetailForm';
import roles from '../../../constants/roles';

const styles = {
  root: {
    padding: '0px',
  },
  card: {
    background: 'none',
    boxShadow: 'none',
    padding: '0px',
  },
};

class SessionDetail extends PureComponent {
  render() {
    const { permissions, history } = this.props;
    if (permissions && permissions !== roles.superAdmin) {
      history.push('/');
    }
    return (
      <>
        <Edit {...this.props}>
          <SessionDetailForm history={this.props.history} />
        </Edit>
      </>
    );
  }
}

export default withStyles(styles)(SessionDetail);
