import React, { PureComponent } from 'react';
import { SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import roles from '../../../constants/roles';
import EditToolbarAdmin from './EditToolbarAdmin';

class EditFormAdmin extends PureComponent {
  getRoles = () => {
    return Object.values(roles)
      .filter((item) => item !== 'User')
      .map((item) => ({ id: item, name: item }));
  };

  handleSave = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      position: values.position,
      role: values.role,
    };
    this.props.save(data, '/admins');
  };

  render() {
    return (
      <SimpleForm
        {...this.props}
        save={this.handleSave}
        toolbar={<EditToolbarAdmin />}
        submitOnEnter={false}
      >
        <TextInput source="firstName" label="FirstName" validate={required()} />
        <TextInput source="lastName" label="LastName" validate={required()} />
        <TextInput source="position" label="Position or Title" validate={required()} />
        <SelectInput
          source="role"
          choices={this.getRoles()}
          label="Permissions Level"
          validate={required()}
        />
      </SimpleForm>
    );
  }
}

export default EditFormAdmin;
