import React, { memo } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { sessionItemType } from './constants';
import globalStyles from './styles';

const styles = {
  wrapper: globalStyles.itemWrapper,
  content: globalStyles.itemContent,
  paper: globalStyles.paper,
  draggable: globalStyles.draggable,
};

const SessionItem = ({ item, nextTrackSessionId, classes }) => {
  const [, dragRef] = useDrag(
    () => ({
      type: sessionItemType,
      item: {
        id: nextTrackSessionId,
        sessionId: item.id,
        trackId: null,
        name: item.name,
        isNew: true,
      },
    }),
    [item, nextTrackSessionId],
  );

  return (
    <div ref={dragRef} className={`${classes.wrapper} ${classes.draggable}`}>
      <Paper elevation={1} className={classes.paper}>
        <span className={classes.content}>{item.name}</span>
      </Paper>
    </div>
  );
};

export default memo(withStyles(styles)(SessionItem));
