import React from 'react';
import AdvertisementList from './AdvertisementList';
import AnnouncementBarManager from './AnnouncementBarManager';

const Advertisement = ({ ...props }) => {
  return (
    <div>
      <AdvertisementList {...props} />
      <AnnouncementBarManager />
    </div>
  );
};

export default Advertisement;
