import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitleWithCloseIcon = withStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose, className, align = '' } = props;
  return (
    <MuiDialogTitle className={className} align={align}>
      <div>{children}</div>
      {onClose && (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

export default DialogTitleWithCloseIcon;
