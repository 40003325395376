import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import ReloadIcon from '@material-ui/icons/Cached';
import { green, red, blue } from '@material-ui/core/colors';

import { GET_ONE, UPDATE } from 'react-admin';
import MuiDialogTitle from '../DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import dataProvider from '../../providers/dataProvider';
import NumberFormatInput from '../NumberFormatInput/NumberFormatInput';
import DotsMenu from '../DotsMenu/DotsMenu';
import toFindFileInObjectByString from '../../utils/toFindFileInObjectByString';

const styles = (theme) => ({
  progressBar: {
    width: '12px !important',
    height: '12px !important',
    margin: '0 10px',
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    marginBottom: '20px',
    textAlign: 'center',
  },
  dialogActions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    display: 'inline-block',
  },
  addIcon: {
    cursor: 'pointer',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: red[500],
  },
  inputTime: {
    width: '100px',
  },
  addRowButton: {
    margin: '20px auto 0px auto',
    display: 'flex',
  },
});

const Alert = ({ video, restartTranscribing }) => {
  if (!video) {
    return '';
  }

  const alerts = [
    <Button variant="outlined" color="default" onClick={() => restartTranscribing(video.id)}>
      <ReloadIcon />
      &nbsp;Restart Transcribe
    </Button>,
  ];

  const iconStyles = (color) => ({
    marginRight: 5,
    verticalAlign: 'middle',
    color: color[500],
  });
  const textStyles = (color) => ({
    background: color[100],
    padding: 5,
    borderRadius: 3,
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  });

  switch (video.transcodingStatus) {
    case 3:
      alerts.push(
        <Typography style={{ ...textStyles(red) }}>
          <ErrorIcon style={{ ...iconStyles(red) }} />
          Error during video transcoding
        </Typography>,
      );
      break;
    case 2:
      alerts.push(
        <Typography style={{ ...textStyles(green) }}>
          <CheckIcon style={{ ...iconStyles(green) }} />
          Video transcoding finished successfuly
        </Typography>,
      );
      break;
    default:
      alerts.push(
        <Typography style={{ ...textStyles(blue) }}>
          <InfoIcon style={{ ...iconStyles(blue) }} />
          Transcoding video...
        </Typography>,
      );
      break;
  }

  if (video.transcodingStatus !== 3) {
    switch (video.transcribeStatus) {
      case 3:
        alerts.push(
          <Typography style={{ ...textStyles(red) }}>
            <ErrorIcon style={{ ...iconStyles(red) }} />
            Error during generating subtitles
          </Typography>,
        );
        break;
      case 2:
        alerts.push(
          <Typography style={{ ...textStyles(green) }}>
            <CheckIcon style={{ ...iconStyles(green) }} />
            Subtitles generated successfuly
          </Typography>,
        );
        break;
      default:
        alerts.push(
          <Typography style={{ ...textStyles(blue) }}>
            <InfoIcon style={{ ...iconStyles(blue) }} />
            Generating subtitles...
          </Typography>,
        );
        break;
    }
  }

  if (video.transcodedAt) {
    alerts.push(
      <Typography style={{ ...textStyles(green) }}>
        <InfoIcon style={{ ...iconStyles(green) }} />
        Video transcoded and updated at {new Date(video.transcodedAt).toLocaleString()}
      </Typography>,
    );
  }

  // eslint-disable-next-line react/no-array-index-key
  return alerts.map((alert, i) => <React.Fragment key={`key${i}`}>{alert}</React.Fragment>);
};

class UploadVideo extends PureComponent {
  emptyRow = { start: '', end: '', text: '' };

  constructor(props) {
    const { record, checkedName } = props;
    super(props);
    this.state = {
      open: false,
      loading: false,
      subtitles: [],
      video: toFindFileInObjectByString(record, checkedName),
    };
  }

  componentDidMount() {
    const { video } = this.state;
    this.setState(() => ({ loading: true }));

    if (!video) {
      return;
    }

    this.checkVideoStatus(video);

    if (video.transcribeStatus === 2) {
      this.getSubtitles(video.id);
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRestartTranscribing = (id) => {
    const { record } = this.props;
    this.setState({ loading: true });
    return dataProvider(UPDATE, `file/video-transcriber`, {
      id,
    }).then((res) => {
      dataProvider(GET_ONE, this.props.checkedResource, { id: record.id }).then((val) => {
        this.setState({ video: val.data?.video || null });
      });
      this.checkVideoStatus(res?.data);
    });
  };

  getSubtitles = (id) => {
    this.setState({ loading: true });
    return dataProvider('GET_ALL', `file/video/${id}/subtitles`)
      .then((res) => {
        if (typeof res.data === 'string') {
          this.setState({ loading: false });
          return;
        }
        this.setState({
          subtitles: res.data.subtitles,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  saveSubtitles = () => {
    const { video } = this.state;

    return dataProvider(UPDATE, `file/video/${video.id}/subtitles`, {
      id: '',
      data: { subtitles: this.state.subtitles },
    }).then((res) => {
      this.setState(() => ({
        subtitles: res.data.subtitles,
      }));
    });
  };

  checkVideoStatus = (video) => {
    const { transcodingStatus, transcribeStatus } = video;

    if (
      transcodingStatus === 3 ||
      transcodingStatus === 2 ||
      transcribeStatus === 3 ||
      transcribeStatus === 2
    ) {
      this.setState({ loading: false });
    }
  };

  handleChange = (value, name, i) => {
    const subs = [...this.state.subtitles];
    subs[i][name] = value;
    this.setState(() => ({
      subtitles: subs,
    }));
  };

  removeAllNewlineCharacter = (subtitles) => {
    return subtitles.map((item) => {
      // eslint-disable-next-line no-return-assign,no-param-reassign
      return (item.text = item.text.replace(/\n/, '#'));
    });
  };

  handleSave = () => {
    this.saveSubtitles();
    this.handleClose();
  };

  handleAction = (action, i) => {
    if (action && action.toLowerCase() === 'add row below') {
      this.insertRow(i);
    }
    if (action && action.toLowerCase() === 'delete') {
      this.deleteRow(i);
    }
  };

  insertRow = (index) => {
    const subtitles = [...this.state.subtitles];
    subtitles.splice(index + 1, 0, { start: '', end: '', text: '' });
    this.setState(() => ({ subtitles }));
  };

  deleteRow = (index) => {
    const subtitles = [...this.state.subtitles];
    if (subtitles.length > 1) {
      this.setState(() => ({
        subtitles: subtitles.filter((_, i) => i !== index),
      }));
    } else {
      this.setState(() => ({ subtitles: [{ ...this.emptyRow }] }));
    }
  };

  toValidAllList = () => {
    return this.state.subtitles.every((item) => {
      if (item.start && item.end && item.text) {
        return true;
      }
      return false;
    });
  };

  renderRow = () => {
    const { classes } = this.props;
    return this.state.subtitles.map((item, i) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container spacing={24} key={i}>
          <Grid item md={2}>
            <TextField
              className={classes.inputTime}
              error={!item.start}
              label="Start"
              required
              onChange={(event) => this.handleChange(event.target.value, 'start', i)}
              value={item.start}
              InputProps={{
                inputComponent: NumberFormatInput,
              }}
              margin="normal"
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="End"
              className={classes.inputTime}
              error={!item.end}
              required
              onChange={(event) => this.handleChange(event.target.value, 'end', i)}
              value={item.end}
              InputProps={{
                inputComponent: NumberFormatInput,
                required: true,
              }}
              margin="normal"
            />
          </Grid>
          <Grid item md={7}>
            <TextField
              label="Text"
              error={!item.text}
              required
              fullWidth
              multiline
              value={item.text}
              onChange={(event) => this.handleChange(event.target.value, 'text', i)}
              margin="normal"
            />
          </Grid>
          <Grid item md={1}>
            <DotsMenu
              onChange={(e) => this.handleAction(e, i)}
              options={['Add row below', 'Delete']}
            />
          </Grid>
        </Grid>
      );
    });
  };

  render() {
    const { classes, record } = this.props;
    const { loading, subtitles, video } = this.state;
    if (!video) {
      return null;
    }

    return (
      <>
        <Button
          size="small"
          color="primary"
          className={classes.button}
          disabled={loading}
          onClick={this.handleClickOpen}
        >
          {loading ? <CircularProgress className={classes.progressBar} /> : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading
            ? 'Closed Captioning in Progress'
            : subtitles.length
            ? 'Update Closed Captions'
            : 'No Video Transcripts Are Detected'}
        </Button>

        {!loading && <Alert video={video} restartTranscribing={this.handleRestartTranscribing} />}

        <Dialog fullWidth maxWidth="md" onClose={this.handleClose} open={this.state.open}>
          <form className={classes.container}>
            <MuiDialogTitle className={classes.dialogTitle} onClose={this.handleClose}>
              Closed Captions
            </MuiDialogTitle>
            <DialogContent>
              <Typography variant="title">{record.name}</Typography>
              <p>
                <a target="_blank" href={video.url} rel="noreferrer">
                  {video.originalName}
                </a>
              </p>
              <br />
              <Grid container spacing={24}>
                <Grid item md={2}>
                  Start time
                </Grid>
                <Grid item md={2}>
                  End time
                </Grid>
                <Grid item md={7}>
                  Closed Caption
                </Grid>
                <Grid item md={1} />
              </Grid>
              {this.renderRow()}
              {!this.state.subtitles.length ? (
                <Grid container spacing={24}>
                  <Grid item md={12}>
                    <Button
                      color="primary"
                      className={classes.addRowButton}
                      onClick={() => this.insertRow(-1)}
                    >
                      Add first row
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                size="large"
                onClick={this.handleSave}
                color="primary"
                disabled={!this.toValidAllList()}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(UploadVideo);
