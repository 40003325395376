import React, { PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  ImageInput,
  required,
  NumberInput,
  BooleanInput,
} from 'react-admin';

import EditToolbarCustomer from './EditToolbarCustomer';
import ImgField from '../../../components/ImgField/ImgField';
import uploadService from '../../../utils/upload';

class EditFormCustomer extends PureComponent {
  uploadImg = (img) => {
    if (img && typeof img === 'object') {
      const formData = new FormData();
      formData.append('file', img.rawFile);
      return uploadService(formData);
    }
    return Promise.resolve({ data: { fileName: img } });
  };

  handleSave = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      apAccess: values.apAccess,
      apUsername: values.apUsername,
      apId: values.apId,
      apTopicId: values.apTopicId,
    };
    this.uploadImg(values.picture).then((res) => {
      data.picture = res.data.fileName;
      this.props.save(data, 'list');
    });
  };

  render() {
    return (
      <SimpleForm
        {...this.props}
        toolbar={<EditToolbarCustomer />}
        save={this.handleSave}
        submitOnEnter={false}
        redirect="list"
      >
        <ImageInput
          source="picture"
          label="Picture"
          accept="image/*"
          format={(v) => (typeof v === 'string' ? { picture: v } : v)}
        >
          <ImgField width="100" height="100" source="picture" />
        </ImageInput>
        <TextInput source="firstName" label="First Name" validate={required()} />
        <TextInput source="lastName" label="Last Name" validate={required()} />
        <TextInput source="email" label="Email" validate={required()} />
        <BooleanInput source="apAccess" label="AP Access" />
        <TextInput source="apUsername" label="AP Username" />
        <NumberInput source="apId" label="AP Id" />
        <NumberInput source="apTopicId" label="AP Topic Id" />
      </SimpleForm>
    );
  }
}

export default EditFormCustomer;
