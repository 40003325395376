import React, { useState, useCallback, useEffect, memo } from 'react';
import { Filter } from 'react-admin';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import dataProvider from '../../../../providers/dataProvider';

const QuestionnairesFilter = (props) => {
  const { setFilters, filterValues, context } = props;
  const [selectedProgram, setSelectedProgram] = useState(filterValues?.program || '');
  const [programs, setPrograms] = useState([]);
  useEffect(() => {
    if (!programs.length && context === 'form') {
      dataProvider('GET_ALL', `programs`, {}).then((res) => {
        setPrograms(res.data.programs);
      });
    }
  }, [programs, context]);
  const handleChangeProgram = useCallback(
    (event) => {
      setFilters({ program: Number(event.target.value) });
      setSelectedProgram(Number(event.target.value));
    },
    [setFilters],
  );

  return (
    <Filter {...props}>
      <FormControl fullWidth margin="normal" source="program" style={{ minWidth: 150 }}>
        <InputLabel htmlFor="program">Program</InputLabel>
        <Select
          value={selectedProgram}
          onChange={handleChangeProgram}
          inputProps={{
            name: 'program',
            id: 'program',
          }}
        >
          {programs.map((program) => (
            <MenuItem value={program.id} key={`id${program.id}`}>
              {program.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Filter>
  );
};

export default memo(QuestionnairesFilter);
