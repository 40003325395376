import React, { PureComponent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePagination from '@material-ui/core/TablePagination';
import EmptyBlock from './EmptyBlock';
import dataProvider from '../../../providers/dataProvider';

const formatDate = (date) => {
  return date ? format(date, 'MM/DD/YYYY') : 'unknown';
};

const displayIsExternalValue = (isExternal, anotherValue) => {
  if (isExternal) {
    return '-';
  }

  return anotherValue;
};

class ProgramHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoading: false,
      page: 0,
      total: 0,
      perPage: 10,
    };
  }

  componentDidMount() {
    this.readList();
  }

  readList = (page = 0, perPage = 10) => {
    const { userId } = this.props;
    this.setState({ isLoading: true });
    dataProvider('GET_LIST', `admin/users/${userId}/programs`, {
      pagination: { page: page + 1, perPage },
      sort: {},
    })
      .then((res) => {
        this.setState({
          list: res.data.userPrograms,
          total: res.data.total,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleChangePage = (e, page) => {
    this.setState({ page });
    this.readList(page, this.state.perPage);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({ perPage: e.target.value });
    this.readList(this.state.page, e.target.value);
  };

  render() {
    const { list, isLoading, page, total, perPage } = this.state;
    if (isLoading) {
      return <LinearProgress />;
    }
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program Name</TableCell>
              <TableCell>Is External</TableCell>
              <TableCell>Purchase Date</TableCell>
              <TableCell>Date Started</TableCell>
              <TableCell>Last Session</TableCell>
              <TableCell>Completed</TableCell>
              <TableCell>Schedule</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length ? (
              list.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.program.name}</TableCell>
                  <TableCell>{row.program.isExternal ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{format(row.createdAt, 'MM/DD/YYYY')}</TableCell>
                  <TableCell>
                    {displayIsExternalValue(
                      row.program.isExternal,
                      formatDate(row.program.firstSession),
                    )}
                  </TableCell>
                  <TableCell>
                    {displayIsExternalValue(
                      row.program.isExternal,
                      formatDate(row.program.latestSession),
                    )}
                  </TableCell>
                  <TableCell>
                    {displayIsExternalValue(
                      row.program.isExternal,
                      row.program.latestSessionCompleted ? 'Yes' : 'No',
                    )}
                  </TableCell>
                  <TableCell>
                    {displayIsExternalValue(
                      row.program.isExternal,
                      row.schedule ? Object.keys(row.schedule).join(', ') : '',
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="6">
                  <EmptyBlock
                    title="Customer does not own any programs."
                    body="Program history will appear here once customer has purchased a program."
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          page={page}
          rowsPerPage={perPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onChangePage={this.handleChangePage}
        />
      </>
    );
  }
}

export default ProgramHistory;
