import React, { PureComponent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { format, differenceInMinutes } from 'date-fns';
import TablePagination from '@material-ui/core/TablePagination';
import PONDER from '../../../constants/ponder';

import EmptyBlock from './EmptyBlock';
import dataProvider from '../../../providers/dataProvider';

class WorkoutHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoading: false,
      page: 0,
      total: 0,
      perPage: 10,
    };
  }

  componentDidMount() {
    this.readList();
  }

  readList = (page = 0, perPage = 10) => {
    const { userId } = this.props;
    this.setState(() => ({ isLoading: true }));
    dataProvider('GET_LIST', `admin/users/${userId}/workout/session`, {
      filter: { completed: true },
      pagination: { page: page + 1, perPage },
      sort: {},
    }).then((res) => {
      this.setState(() => ({
        list: res.data.sessions,
        isLoading: false,
        total: res.data.total,
      }));
    });
  };

  handleChangePage = (e, page) => {
    this.setState(() => ({ page }));
    this.readList(page, this.state.perPage);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState(() => ({ perPage: e.target.value }));
    this.readList(this.state.page, e.target.value);
  };

  getTimeSpend = (start, complete) => {
    if (!start || !complete) {
      return 'n/a';
    }
    return `${differenceInMinutes(complete, start)} Mins`;
  };

  render() {
    const { list, isLoading, page, total, perPage } = this.state;
    if (isLoading) {
      return <LinearProgress />;
    }
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Program Name</TableCell>
              <TableCell>Session</TableCell>
              <TableCell>Post Prep Assessment</TableCell>
              <TableCell>Time Spent</TableCell>
              <TableCell>Ease / Quality</TableCell>
              <TableCell>Ponder</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length ? (
              list.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.completedAt ? format(item.completedAt, 'MM/DD/YYYY') : 'unknown'}
                  </TableCell>
                  <TableCell>{item.session.program.name}</TableCell>
                  <TableCell>{item.session.name}</TableCell>
                  <TableCell>{PONDER[item.preparationPhaseAnswer]}</TableCell>
                  <TableCell>{this.getTimeSpend(item.startedAt, item.completedAt)}</TableCell>
                  <TableCell>
                    {item.workoutSurvey
                      ? `${PONDER[item.workoutSurvey.ease]} / ${PONDER[item.workoutSurvey.quality]}`
                      : ''}
                  </TableCell>
                  <TableCell>{item.workoutSurvey ? item.workoutSurvey.ponder : ''}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="7">
                  <EmptyBlock
                    title="Customer has not completed any sessions yet."
                    body="All completed sessions will appear here."
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          page={page}
          rowsPerPage={perPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onChangePage={this.handleChangePage}
        />
      </>
    );
  }
}

export default WorkoutHistory;
