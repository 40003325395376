import React, { memo, useState, useCallback } from 'react';
import {
  List,
  TextField,
  Filter,
  Datagrid,
  FunctionField,
  TextInput,
  DeleteButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import roles from '../../constants/roles';
import uploadVideoService from '../../utils/uploadVideo';

const VideoProcessingStatus = {
  1: 'Processing',
  2: 'Complete',
  3: 'Error',
};

const styles = {
  center: {
    textAlign: 'center',
  },
  uploadButton: {
    marginBottom: '1rem',
  },
  actionButton: {
    padding: 0,
  },
};

const ListMediaFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

const ListMedia = ({ classes, ...props }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      uploadVideoService({ rawFile: file }, (progressEvent) => {
        const currentProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setProgress(currentProgress);
      }).finally(() => {
        setUploading(false);
      });
    }
  }, []);

  const handleEdit = useCallback(
    (event, record) => {
      event.stopPropagation();
      props.history.push(`/file/video/${record.id}`);
    },
    [props.history],
  );

  return (
    <>
      <input
        accept="video/*"
        style={{ display: 'none' }}
        id="upload-video-button"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="upload-video-button">
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.uploadButton}
          disabled={uploading}
        >
          {uploading ? `${progress}% Uploaded` : 'Upload Video'}
        </Button>
      </label>
      <List {...props} exporter={false} bulkActions={false} filters={<ListMediaFilter />}>
        <Datagrid rowClick="show">
          <TextField label="File Name" source="originalName" sortable={false} />
          <TextField label="Mux Id" source="muxPlaybackId" sortable={false} />
          <TextField label="Created At" source="createdAt" sortable />
          <FunctionField
            label="Processing Status"
            source="transcodingStatus"
            render={(record) => VideoProcessingStatus[record.transcodingStatus]}
            sortable={false}
          />
          <FunctionField
            label="Exercises"
            sortable={false}
            render={(record) => (
              <div>
                [
                {record?.exercises?.map((exercise, index) => (
                  <span key={exercise.id}>
                    {exercise.id}
                    {index < record.exercises.length - 1 ? ', ' : ''}
                  </span>
                ))}
                ]
              </div>
            )}
          />
          <FunctionField
            label="Stream"
            sortable={false}
            render={(record) => (
              <IconButton
                href={`https://stream.mux.com/${record.muxPlaybackId}/low.mp4`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.actionButton}
                size="small"
              >
                <PlayArrowIcon />
              </IconButton>
            )}
          />
          <FunctionField
            label="Size"
            sortable
            render={(record) => `${(record.size / 1024 / 1024).toFixed(2)} MB`}
          />
          {props.permissions === roles.superAdmin && (
            <FunctionField
              label="Actions"
              render={(record) => (
                <>
                  <IconButton
                    onClick={(e) => handleEdit(e, record)}
                    className={classes.actionButton}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  {record?.exercises?.length === 0 && (
                    <DeleteButton resource="file/video" record={record} />
                  )}
                </>
              )}
            />
          )}
        </Datagrid>
      </List>
    </>
  );
};

export default memo(withStyles(styles)(ListMedia));
