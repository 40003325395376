import React from 'react';
import { MenuItemLink, getResources } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    display: 'flex',
    position: 'fixed',
    background: 'white',
    minHeight: '48px',
    width: '100%',
    zIndex: '1000',
    borderBottom: '1px solid #dcdcdc',
    backgroundColor: 'white',
  },
};
const AppMenu = (props) => {
  return (
    <Toolbar className={props.classes.wrapper}>
      <MenuItemLink to="/" primaryText="DASHBOARD" />
      {props.resources.map((resource) => {
        if (!resource.options.label) {
          return null;
        }
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options.label.toUpperCase()}
          />
        );
      })}
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  resources: getResources(state),
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(AppMenu)));
