import React, { PureComponent, cloneElement, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DeleteButton,
  CardActions,
  Button,
  CreateButton,
  CREATE,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { green, red } from '@material-ui/core/colors';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import FilterCustomer from '../FilterCustomer/FilterCustomer';
import roles from '../../../constants/roles';
import dataProvider from '../../../providers/dataProvider';

const styles = {
  action: {
    textAlign: 'right',
  },
};

const ListActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => {
  const [syncPosseLoading, setSyncPosseLoading] = useState(false);
  const [syncPosseDone, setSyncPosseDone] = useState(undefined);
  const handleSynchronizePosseAccess = () => {
    setSyncPosseLoading(true);
    dataProvider(CREATE, `users/posse-access-sync-all`, { data: {} })
      .then(() => {
        setSyncPosseDone(true);
      })
      .catch(() => {
        setSyncPosseDone(false);
      })
      .finally(() => {
        setSyncPosseLoading(false);
      });
  };

  return (
    <CardActions>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <Button
        onClick={handleSynchronizePosseAccess}
        label="Sync AP users"
        disabled={syncPosseLoading}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          syncPosseDone === true ? (
            <DoneIcon style={{ color: green[500] }} />
          ) : syncPosseDone === false ? (
            <ErrorIcon style={{ color: red[500] }} />
          ) : (
            ''
          )
        }
      </Button>
    </CardActions>
  );
};

class ListCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      record: null,
    };
  }

  handleAction = (action, record) => {
    if (action && action.toLowerCase() === 'delete') {
      this.setState({ isOpen: true, record });
    }
    if (action && action.toLowerCase() === 'edit') {
      this.props.history.push(`/users/${record.id}`);
    }
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  render() {
    const { classes, permissions, ...props } = this.props;
    return (
      <>
        <List
          {...props}
          title="Customers"
          exporter={false}
          bulkActions={false}
          sort={{}}
          filter={{}}
          filters={<FilterCustomer />}
          filterDefaultValues={{ order_by: '-createdAt' }}
          actions={<ListActions />}
        >
          <Datagrid rowClick="show">
            <TextField source="lastName" sortable={false} />
            <TextField source="firstName" sortable={false} />
            <TextField source="email" sortable={false} />
            <FunctionField
              render={(record) =>
                record.subscription && record.subscription.isActive ? 'Yes' : 'No'
              }
              label="Alpha Posse (Y/N)"
            />
            {permissions === roles.superAdmin || permissions === roles.admin ? (
              <FunctionField
                cellClassName={classes.action}
                label=""
                onClick={(e) => {
                  e.stopPropagation();
                }}
                render={(record) => (
                  <DotsMenu
                    onChange={(e) => this.handleAction(e, record)}
                    options={['delete', 'edit']}
                  />
                )}
              />
            ) : null}
          </Datagrid>
        </List>
        <DeleteAlertDialog
          title="Delete customer"
          subtitle="Are you sure you want to delete customer ?"
          description="Customer will be deleted permanently."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            size="large"
            redirect={() => this.handleCloseModalDelete()}
            basePath={this.props.basePath}
            record={this.state.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

export default withStyles(styles)(ListCustomers);
