import React, { memo } from 'react';
import { FormDataConsumer, NumberInput, required } from 'react-admin';
import paymentType from '../../../constants/paymentType';

const PaymentTypeAdditionalField = (props) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData, ...rest }) => {
        switch (formData.paymentType) {
          case paymentType.paymentPlan.id: {
            return (
              <NumberInput
                source="paymentCount"
                label="Payment Count"
                validate={required()}
                {...rest}
              />
            );
          }
          case paymentType.subscription.id: {
            return (
              <NumberInput
                source="subscriptionBillingPeriod"
                label="Subscription Billing Period"
                validate={required()}
                helperText={
                  <span>
                    this field uses for showing billing period on admin app only, subscription
                    options handled by stripePriceId
                  </span>
                }
                {...rest}
              />
            );
          }
          default: {
            return null;
          }
        }
      }}
    </FormDataConsumer>
  );
};

export default memo(PaymentTypeAdditionalField);
