import React, { memo } from 'react';
import { Edit, SimpleForm, TextInput, DateInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  // Add any custom styles here if needed
};

const EditMedia = ({ ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="originalName" label="File Name" />
        <TextInput source="muxPlaybackId" label="Mux Id" disabled />
        <DateInput source="createdAt" label="Created At" disabled />
        {/* Add more fields as needed */}
      </SimpleForm>
    </Edit>
  );
};

export default memo(withStyles(styles)(EditMedia));
