import React, { memo, useState, useCallback } from 'react';
import { List, TextField, Datagrid, FunctionField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import roles from '../../constants/roles';
import { paymentTypeById } from '../../constants/paymentType';
import getPrice from '../../utils/getPrice';
import DotsMenu from '../../components/DotsMenu/DotsMenu';
import DeleteDialog from './components/DeleteDialog';

const styles = {
  center: {
    textAlign: 'center',
  },
};

const ListProducts = ({ classes, ...props }) => {
  const [isOpenDelete, changeIsOpenDelete] = useState(false);
  const [deletingRecord, changeDeletingRecord] = useState(null);

  const handleCloseModalDelete = useCallback(() => {
    changeIsOpenDelete(false);
    changeDeletingRecord(null);
  }, []);

  const handleAction = useCallback(
    (action, record) => {
      if (action && action.toLowerCase() === 'edit') {
        props.history.push(`products/${record.id}`);
        return;
      }
      if (action && action.toLowerCase() === 'delete') {
        changeIsOpenDelete(true);
        changeDeletingRecord(record);
      }
    },
    [props.history],
  );

  return (
    <>
      <List {...props} exporter={false} bulkActions={false}>
        <Datagrid rowClick="show">
          <TextField label="Product Name" source="name" sortable={false} />
          <FunctionField
            label="Payment Type"
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
            render={(record) => paymentTypeById[record.paymentType]}
          />
          <FunctionField
            label="Price, $"
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
            render={(record) => getPrice(record)}
          />
          <TextField
            label="Upsell Price, $"
            source="upsellPrice"
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
          />
          {props.permissions === roles.superAdmin && (
            <FunctionField
              label=""
              cellClassName={classes.center}
              onClick={(e) => {
                e.stopPropagation();
              }}
              render={(record) => (
                <DotsMenu onChange={(e) => handleAction(e, record)} options={['edit', 'delete']} />
              )}
            />
          )}
        </Datagrid>
      </List>
      <DeleteDialog
        record={deletingRecord}
        isOpen={isOpenDelete}
        handleClose={handleCloseModalDelete}
        basePath={props.basePath}
        resource={props.resource}
      />
    </>
  );
};

export default memo(withStyles(styles)(ListProducts));
