import React, { PureComponent } from 'react';

import { Edit } from 'react-admin';
import { withStyles } from '@material-ui/core';
import EditFormProgram from './EditFormProgram';

const styles = {
  root: {
    padding: '0px',
  },
  card: {
    background: 'none',
    boxShadow: 'none',
    padding: '0px',
  },
};

class EditProgram extends PureComponent {
  render() {
    return (
      <Edit {...this.props}>
        <EditFormProgram permissions={this.props.permissions} />
      </Edit>
    );
  }
}

export default withStyles(styles)(EditProgram);
