import React, { memo, useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  SaveButton,
  ImageInput,
  Toolbar,
  LongTextInput,
  NumberInput,
  SelectArrayInput,
  GET_MANY,
} from 'react-admin';
import dataProvider from '../../providers/dataProvider';
import { paymentTypeSelectOptions } from '../../constants/paymentType';
import uploadService from '../../utils/upload';
import removeUnnecessaryProductFields from '../../utils/removeUnnecessaryProductFields';
import RemoveButton from '../../components/RemoveButton/RemoveButton';
import ImgField from '../../components/ImgField/ImgField';
import Loading from '../../components/Loading/Loading';
import DeleteDialog from './components/DeleteDialog';
import PaymentTypeAdditionalField from './components/PaymentTypeAdditionalField';

const uploadImg = (img) => {
  if (img && typeof img === 'object') {
    const formData = new FormData();
    formData.append('file', img.rawFile);
    return uploadService(formData);
  }
  return Promise.resolve({ data: { fileName: img } });
};

const parseOptions = (v) =>
  v?.map((i) => {
    if (typeof i === 'number') {
      return i;
    }
    return i.programId;
  });

const ToolBar = (props) => {
  const [isOpenDeleteModal, changeIsOpenDeleteModal] = useState(false);
  const openDeleteModal = () => {
    changeIsOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    changeIsOpenDeleteModal(false);
  };

  return (
    <>
      <Toolbar
        {...props}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: 'none',
        }}
      >
        <SaveButton {...props} label="save" submitOnEnter={false} />
        <RemoveButton variant="contained" deleteExercise={openDeleteModal} />
      </Toolbar>
      <DeleteDialog
        record={props.record}
        isOpen={isOpenDeleteModal}
        handleClose={closeDeleteModal}
        basePath={props.basePath}
        resource={props.resource}
        redirect="list"
      />
    </>
  );
};

const EditProductForm = ({ record, ...props }) => {
  const [isLoading, changeIsLoading] = useState(false);

  const [options, changeOptions] = useState([]);
  useEffect(() => {
    dataProvider(GET_MANY, 'admin/products/programs').then((res) => changeOptions(res.data));
  }, []);

  const save = (data, redirect) => {
    changeIsLoading(true);
    const { picture, ...rawData } = data;
    uploadImg(picture)
      .then((res) => {
        const newData = removeUnnecessaryProductFields({
          ...rawData,
          picture: res.data.fileName,
          programs: parseOptions(rawData.programs),
        });

        props.save(newData, redirect);
      })
      .finally(() => {
        changeIsLoading(false);
      });
  };

  return (
    <>
      <Loading open={isLoading} />
      <SimpleForm {...props} record={record} save={save} toolbar={<ToolBar />}>
        <TextInput source="name" label="Product Name" validate={required()} />
        <TextInput source="link" label="Checkout Link" />
        <TextInput source="cancelUrl" label="Cancel URL" />
        <TextInput
          source="externalProductId"
          label="Product Id"
          helperText={<span>Used for link between shopping cart and praxis products</span>}
        />
        <SelectInput source="paymentType" choices={paymentTypeSelectOptions} />
        <NumberInput source="price" label="Price, $" validate={required()} />
        <PaymentTypeAdditionalField />
        <TextInput
          source="stripePriceId"
          label="Stripe Price Id"
          helperText={
            <span>
              Required field for Stripe Products, not needed for Samcart
              <br />
              if isPaymentPlan is selected should be subscription stripe price id
            </span>
          }
        />
        <NumberInput source="upsellPrice" label="Upsell Price, $" />
        <TextInput source="upsellStripePriceId" label="Upsell Stripe Price Id" />
        {options.length ? (
          <SelectArrayInput
            source="programs"
            choices={options}
            validate={required()}
            parse={parseOptions}
            format={parseOptions}
          />
        ) : null}
        <LongTextInput source="description" label="Description" validate={required()} />
        <ImageInput
          source="picture"
          label="Add Product Photo"
          accept="image/*"
          validate={required()}
          format={(v) => (typeof v === 'string' ? { picture: v } : v)}
        >
          <ImgField width="100" height="100" source="picture" />
        </ImageInput>
      </SimpleForm>
    </>
  );
};

const EditProduct = (props) => {
  return (
    <Edit {...props}>
      <EditProductForm />
    </Edit>
  );
};

export default memo(EditProduct);
