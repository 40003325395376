import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { CREATE, DELETE, crudGetOne } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import SessionModal from '../SessionModal/SessionModal';

const styles = () => ({
  rowHover: {
    cursor: 'pointer',
  },
  buttonSpacing: {
    marginRight: '10px',
  },
});

class Sessions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openNewSession: false,
      isOpen: false,
      row: null,
      sessions: null,
      loading: false,
      openCopyDialog: false,
      availablePrograms: [],
      selectedProgramId: null,
      programSessions: [],
      selectedSessions: {},
      sessionFilter: '',
    };
  }

  componentDidMount() {
    this.refreshSessions();
  }

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, row: null });
  };

  handleAction = (action, row) => {
    if (action && action.toLowerCase() === 'delete') {
      this.setState({ isOpen: true, row });
    }
    if (action && action.toLowerCase() === 'edit') {
      this.setState({ openNewSession: true, row });
    }
    if (action && action.toLowerCase() === 'duplicate') {
      this.duplicate(row);
    }
  };

  getMenuList = () => {
    const itemsAlways = ['Edit', 'Duplicate', 'Delete'];
    if (this.state.sessions.length === 1) {
      return itemsAlways;
    }
    return ['Edit', 'Duplicate', 'Delete'];
  };

  duplicate = (row) => {
    dataProvider(CREATE, `sessions/${row.id}/duplicate`, {}).then(() => this.refreshSessions());
  };

  refreshSessions = () => {
    this.setState({ loading: true, sessions: [] });
    dataProvider('GET_ALL', `programs/${this.props.record.id}/sessions`, {}).then((res) => {
      this.setState({ sessions: res.data.sessions, loading: false });
    });
  };

  handleClickOpen = () => {
    this.setState({ openNewSession: true });
  };

  handleClose = () => {
    this.setState({ openNewSession: false, row: {} });
  };

  getListOfDays = (sessions) => {
    if (Array.isArray(sessions)) {
      return sessions.map((s) => s.order);
    }
    return [];
  };

  getLastDay = () => {
    const days = this.getListOfDays(this.state.sessions);
    return days[days.length - 1];
  };

  handleSession = (row) => {
    this.props.history.push(`/sessions/${row.id}`);
  };

  handleDelete = () => {
    dataProvider(DELETE, 'sessions', { id: this.state.row.id }).then(() => {
      this.refreshSessions();
      this.props.getOnePrograms(this.props.record.id);
      this.handleCloseModalDelete();
    });
  };

  handleCopyDialogOpen = () => {
    this.setState({ loading: true, openCopyDialog: true });
    dataProvider('GET_LIST', 'programs', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
    }).then((res) => {
      const programs = res.data.filter((prog) => prog.id !== this.props.record.id);
      this.setState({
        availablePrograms: programs,
        loading: false,
        programSessions: [],
        selectedSessions: {},
      });
    });
  };

  handleProgramSelect = (programId) => {
    if (!programId) {
      this.setState({
        selectedProgramId: null,
        programSessions: [],
        selectedSessions: {},
      });
      return;
    }

    this.setState({ loading: true, selectedProgramId: programId });
    dataProvider('GET_ALL', `programs/${programId}/sessions`, {}).then((res) => {
      const { sessions } = res.data;
      const selectedSessions = sessions.reduce((acc, session) => {
        acc[session.id] = true;
        return acc;
      }, {});
      this.setState({
        programSessions: sessions,
        selectedSessions,
        loading: false,
      });
    });
  };

  handleSessionToggle = (sessionId) => {
    this.setState((prevState) => ({
      selectedSessions: {
        ...prevState.selectedSessions,
        [sessionId]: !prevState.selectedSessions[sessionId],
      },
    }));
  };

  handleCopySessions = async () => {
    const sessionsToCopy = this.state.programSessions.filter(
      (session) => this.state.selectedSessions[session.id],
    );

    this.setState({ loading: true });

    try {
      await sessionsToCopy.reduce(async (promise, session) => {
        // Wait for the previous request to complete
        await promise;
        // Add a small delay between requests
        await new Promise((resolve) => setTimeout(resolve, 300));
        // Make the new request
        return dataProvider(CREATE, `sessions/${session.id}/duplicate`, {
          data: { programId: this.props.record.id },
        });
      }, Promise.resolve());

      this.refreshSessions();
      this.handleCopyDialogClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error copying sessions:', error);
    }

    this.setState({ loading: false });
  };

  handleCopyDialogClose = () => {
    this.setState({
      openCopyDialog: false,
      selectedProgramId: null,
      programSessions: [],
      selectedSessions: {},
    });
  };

  handleSelectAllSessions = (checked) => {
    this.setState((prevState) => {
      const selectedSessions = { ...prevState.selectedSessions };
      this.filterSessions().forEach((session) => {
        selectedSessions[session.id] = checked;
      });
      return { selectedSessions };
    });
  };

  handleDeselectAllSessions = () => {
    this.setState({ selectedSessions: {} });
  };

  filterSessions = () => {
    const { programSessions, sessionFilter } = this.state;
    if (!sessionFilter) return programSessions;

    return programSessions.filter((session) =>
      session.name.toLowerCase().includes(sessionFilter.toLowerCase()),
    );
  };

  renderLoading = () => {
    if (this.state.loading) {
      return (
        <div style={{ padding: '60px 0', width: '100%' }}>
          <LinearProgress />
        </div>
      );
    }
    return null;
  };

  renderTableBody = () => {
    if (!this.state.sessions) {
      return null;
    }
    const { classes } = this.props;
    const { sessions } = this.state;
    return (
      <TableBody>
        {sessions.map((row, i) => (
          <TableRow key={row.id}>
            <TableCell
              className={classes.rowHover}
              component="th"
              scope="row"
              onClick={() => this.handleSession(row)}
            >
              {row.id}
            </TableCell>
            <TableCell className={classes.rowHover} onClick={() => this.handleSession(row)}>
              {row.name}
            </TableCell>
            <TableCell className={classes.rowHover} onClick={() => this.handleSession(row)}>
              {Object.keys(row.durationOptions).join(', ')}
            </TableCell>
            <TableCell className={classes.rowHover} onClick={() => this.handleSession(row)}>
              {row.description}
            </TableCell>
            <TableCell align="right">
              <DotsMenu
                onChange={(e) => this.handleAction(e, row)}
                options={this.getMenuList(row, i)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  renderModalWindow = () => {
    if (!this.state.openNewSession) {
      return null;
    }
    return (
      <SessionModal
        refreshList={this.refreshSessions}
        programId={this.props.record.id}
        initialValues={this.state.row}
        listDays={this.getListOfDays(this.props.record.sessions)}
        handleClose={this.handleClose}
        isOpen={this.state.openNewSession}
      />
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
            className={classes.buttonSpacing}
          >
            New session
          </Button>
          <Button variant="outlined" color="primary" onClick={this.handleCopyDialogOpen}>
            Copy Sessions from Program
          </Button>
        </div>
        {this.renderModalWindow()}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="minWidth30px" align="center">
                Id
              </TableCell>
              <TableCell className="minWidth60px" align="right">
                Session Name
              </TableCell>
              <TableCell className="minWidth60px" align="center">
                Duration Options
              </TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell className="minWidth30px" align="right">
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>
          {this.renderTableBody()}
        </Table>
        {this.renderLoading()}
        <DeleteAlertDialog
          title={
            this.state.row && this.state.row.isBasic
              ? `Delete Basic Level Session`
              : `Delete Session`
          }
          subtitle="Are you sure you want to delete session?"
          description={
            this.state.row && this.state.row.isBasic
              ? ` If you continue, the basic level session will be permanently deleted from the sessions in this program.`
              : `If you delete program, all session information will be deleted and customers will no longer have access to it.`
          }
          record={this.state.row}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <Button color="secondary" style={{ color: '#f44336' }} onClick={this.handleDelete}>
            DELETE
          </Button>
        </DeleteAlertDialog>
        <Dialog
          open={this.state.openCopyDialog}
          onClose={this.handleCopyDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Copy Sessions from Another Program</DialogTitle>
          <DialogContent>
            {this.state.loading ? (
              <LinearProgress />
            ) : (
              <>
                <select
                  style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
                  onChange={(e) => this.handleProgramSelect(e.target.value)}
                  value={this.state.selectedProgramId || ''}
                >
                  <option value="">Select a program</option>
                  {this.state.availablePrograms.map((program) => (
                    <option key={program.id} value={program.id}>
                      {program.name}
                    </option>
                  ))}
                </select>

                {this.state.programSessions.length > 0 && (
                  <>
                    <div style={{ marginBottom: '20px' }}>
                      <input
                        type="text"
                        placeholder="Search sessions..."
                        value={this.state.sessionFilter}
                        onChange={(e) => this.setState({ sessionFilter: e.target.value })}
                        style={{
                          width: '100%',
                          padding: '8px',
                          borderRadius: '4px',
                          border: '1px solid #e0e0e0',
                          marginBottom: '10px',
                        }}
                      />
                      <Button
                        onClick={() => this.handleSelectAllSessions(true)}
                        color="primary"
                        size="small"
                        style={{ marginRight: '10px' }}
                      >
                        Select All
                      </Button>
                      <Button
                        onClick={() => this.handleDeselectAllSessions()}
                        color="primary"
                        size="small"
                      >
                        Deselect All
                      </Button>
                    </div>
                    <div
                      style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                        gap: '12px',
                        padding: '8px',
                      }}
                    >
                      {this.filterSessions().map((session) => (
                        <div
                          key={session.id}
                          style={{
                            padding: '8px',
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                            backgroundColor: '#f5f5f5',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!this.state.selectedSessions[session.id]}
                                onChange={() => this.handleSessionToggle(session.id)}
                                color="primary"
                              />
                            }
                            label={
                              <div>
                                <div style={{ fontWeight: 500 }}>{session.name}</div>
                                <div style={{ fontSize: '0.875rem', color: '#666' }}>
                                  ID: {session.id}
                                  <br />
                                  Duration: {Object.keys(session.durationOptions).join(', ')}
                                </div>
                              </div>
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCopyDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleCopySessions}
              color="primary"
              disabled={!Object.values(this.state.selectedSessions).some((v) => v)}
            >
              Copy Selected Sessions
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const getOnePrograms = (id) => {
  return crudGetOne('programs', id, '/programs', true);
};
const enhance = compose(withRouter, connect(undefined, { getOnePrograms }));

Sessions.propsType = {
  source: PropTypes.string,
  values: PropTypes.object.isRequired,
  permissions: PropTypes.string.isRequired,
};

export default withStyles(styles)(enhance(Sessions));
