import * as React from 'react';
import { TextInput, Filter } from 'react-admin';

const ExercisesFilter = (props) => {
  return (
    <>
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
      </Filter>
    </>
  );
};

export default ExercisesFilter;
