import axios from 'axios';
import * as Sentry from '@sentry/react';
import dataProvider from '../providers/dataProvider';

const uuidv1 = require('uuid/v1');

/**
 * Create video file in rest-api database using mux upload id
 * @param {*} fileName
 * @param {*} muxUploadId Mux upload Id
 * @param {*} file
 * @returns FileVideo
 */
const createFileInDb = (fileName, muxUploadId, file) => {
  return dataProvider('CREATE', 'file/video', {
    data: {
      fileName,
      muxUploadId,
      originalName: file.name,
      size: file.size,
    },
  });
};

/**
 * Upload file by uploadUrl
 * @param {*} uploadUrl Mux upload url
 * @param {*} file
 * @param {*} progressFunc
 * @returns void
 */
const uploadFile = (uploadUrl, file, progressFunc) => {
  const options = {
    headers: { 'Content-Type': file.type },
    onUploadProgress: progressFunc,
  };

  return axios.put(uploadUrl, file, options);
};

const uploadVideoService = (video, progressFunc = null) => {
  const file = video.rawFile;
  const ext = file.name.split('.').pop();
  const fileName = uuidv1();

  // Mux upload id
  let uploadId = null;
  // Mux upload url
  let uploadUrl = null;
  // video id from rest-api database
  let videoId = null;

  return dataProvider('CREATE_MUX_UPLOAD', 'mux/upload', {})
    .then((res) => {
      // create uploadId and uploadUrl from mux
      uploadId = res.data.id;
      // The URL from the upload API is resumable, and the file can be uploaded using a `PUT` request
      uploadUrl = res.data.url;
    })
    .then(() => {
      return createFileInDb(`${fileName}.${ext}`, uploadId, file);
    })
    .then((res) => {
      // video id from rest-api database
      videoId = res.data.id;
      //  Upload file to mux
      return uploadFile(uploadUrl, file, progressFunc);
    })
    .then(() => ({
      id: videoId,
    }))
    .catch((err) => {
      Sentry.captureException('Error while video uploading', err);
      return false;
    });
};
export default uploadVideoService;
