import React, { memo, useState, useCallback, useEffect } from 'react';
import { SaveButton, CREATE, GET_ONE, UPDATE } from 'react-admin';
import {
  ListItem,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  ListItemSecondaryAction,
  IconButton,
  List,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import dataProvider from '../../../../providers/dataProvider';
import { trackType } from '../../../Programs/ShowProgram/ProgramTrack/constants';

const CreateQuestion = ({ handleCreateQuestion, handleChangeLoading, editQuestionId }) => {
  const [error, setError] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [multiSelectionVal, setMultiSelectionVal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState('');

  useEffect(() => {
    dataProvider('GET_ALL', `programs`, {}).then((res) => {
      setPrograms(res.data.programs);
    });
  }, []);

  useEffect(() => {
    if (!editQuestionId) {
      return;
    }
    dataProvider(GET_ONE, `questionnaire/${editQuestionId}`, {}).then(({ data }) => {
      setQuestionText(data.title);
      setDescriptionText(data.description);
      setMultiSelectionVal(data.multiSelection);
      setSelectedOrder(data.order);

      if (data.type === trackType) {
        setSelectedProgram(data.programId);
      }

      setQuestionnaireAnswers(
        data.answers.map((i) => ({ id: i.id, order: i.order, title: i.title, value: i.value })),
      );
    });
  }, [editQuestionId]);

  const handleSaveChanges = useCallback(() => {
    const questionnaire = {
      title: questionText,
      description: descriptionText,
      type: selectedProgram ? 'program' : 'general',
      multiSelection: multiSelectionVal,
      order: selectedOrder,
      answers: questionnaireAnswers,
      programId: selectedProgram,
    };
    if (!selectedProgram) {
      delete questionnaire.programId;
    }
    if (!selectedOrder) {
      delete questionnaire.order;
    }

    setError('');
    handleChangeLoading(true);
    const method = editQuestionId ? UPDATE : CREATE;
    const url = `admin/questions`;
    dataProvider(method, url, { id: editQuestionId || '', data: questionnaire })
      .then(() => {
        handleChangeLoading(false);
        handleCreateQuestion();
      })
      .catch((e) => {
        setError(e.toString());
        handleChangeLoading(false);
      });
  }, [
    selectedProgram,
    handleCreateQuestion,
    handleChangeLoading,
    questionText,
    descriptionText,
    multiSelectionVal,
    selectedOrder,
    questionnaireAnswers,
    editQuestionId,
  ]);

  const handleChangeText = useCallback((event) => {
    setQuestionText(event.target.value);
  }, []);

  const handleChangeDescription = useCallback((event) => {
    setDescriptionText(event.target.value);
  }, []);

  const handleChangeMultiselect = useCallback(() => {
    setMultiSelectionVal(!multiSelectionVal);
  }, [multiSelectionVal]);

  const handleChangeProgram = useCallback((event) => {
    setSelectedProgram(Number(event.target.value));
  }, []);

  const handleChangeOrder = useCallback((event) => {
    setSelectedOrder(Number(event.target.value));
  }, []);

  const handleCreateAnswer = useCallback(() => {
    let answers = [
      ...questionnaireAnswers,
      {
        order: questionnaireAnswers.length + 1,
        title: '',
        value: '',
      },
    ];

    answers = answers.map((answer, index) => {
      return {
        ...answer,
        value: `${index + 1}`,
      };
    });
    setQuestionnaireAnswers(answers);
  }, [questionnaireAnswers]);
  const handleChangeAnswer = useCallback(
    (key, value, order) => {
      const index = questionnaireAnswers.findIndex((a) => a.order === order);
      const answers = [...questionnaireAnswers];
      answers[index][key] = value;
      setQuestionnaireAnswers(answers);
    },
    [questionnaireAnswers],
  );
  const deleteAnswer = useCallback(
    (order) => {
      let answers = [...questionnaireAnswers];
      const index = answers.findIndex((a) => a.order === order);
      answers.splice(index, 1);
      answers = answers.map((answer, i) => ({
        ...answer,
        order: i + 1,
      }));
      setQuestionnaireAnswers(answers);
    },
    [questionnaireAnswers],
  );

  return (
    <Dialog open maxWidth="sm" onClose={handleCreateQuestion}>
      <DialogContent>
        <form autoComplete="off" onSubmit={handleSaveChanges}>
          <h3>{editQuestionId ? 'Update Question' : 'Create Question'}</h3>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              label="Question text"
              value={questionText}
              onChange={handleChangeText}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Description"
              value={descriptionText}
              onChange={handleChangeDescription}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Order (leave empty to use next available)"
              value={selectedOrder}
              onChange={handleChangeOrder}
              type="number"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              control={
                <Switch
                  checked={multiSelectionVal}
                  onChange={handleChangeMultiselect}
                  value="Multiselect"
                />
              }
              label="Multiselect"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="program">
              Program (leave empty to add to common questionnaire)
            </InputLabel>
            <Select
              value={selectedProgram}
              onChange={handleChangeProgram}
              inputProps={{
                name: 'program',
                id: 'program',
              }}
            >
              {programs.map((program) => (
                <MenuItem value={program.id} key={`id${program.id}`}>
                  {program.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption" style={{ marginTop: 10 }}>
            Answers:
          </Typography>
          <List>
            {questionnaireAnswers.map((answer) => (
              <ListItem
                key={`answer${answer.order}`}
                style={{ padding: '0 0 20px 0', alignItems: 'baseline' }}
              >
                <Typography variant="button">{answer.order}.</Typography>
                <TextField
                  required
                  placeholder="Answer title"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={answer.title}
                  onChange={(e) => handleChangeAnswer('title', e.target.value, answer.order)}
                  style={{ marginRight: 10 }}
                />
                <TextField
                  required
                  placeholder="Answer value"
                  value={answer.value || ''}
                  style={{ margin: '0 10px 0 0', width: 100 }}
                  onChange={(e) => handleChangeAnswer('value', e.target.value, answer.order)}
                />
                <ListItemSecondaryAction onClick={() => deleteAnswer(answer.order)}>
                  <IconButton aria-label="Delete">
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button variant="contained" color="primary" onClick={handleCreateAnswer}>
            <Add />
            Add Answer
          </Button>
        </form>
        {error && <Typography style={{ color: 'red' }}>{error}</Typography>}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'right' }}>
        <Button onClick={handleCreateQuestion}>
          <div>Cancel</div>
        </Button>
        <SaveButton
          onClick={handleSaveChanges}
          disabled={!questionText || questionnaireAnswers.length <= 1}
        />
      </DialogActions>
    </Dialog>
  );
};

export default memo(CreateQuestion);
