import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, SelectInput, required } from 'react-admin';
import { AchievementType, TriggerType } from './achievementConstants';

const AchievementCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" multiline />
      <NumberInput source="programId" defaultValue={null} />
      <SelectInput
        source="type"
        choices={Object.entries(AchievementType).map(([key, value]) => ({
          id: parseInt(key, 10),
          name: value,
        }))}
      />
      <SelectInput
        source="triggerType"
        choices={Object.entries(TriggerType).map(([key, value]) => ({
          id: parseInt(key, 10),
          name: value,
        }))}
      />
      <NumberInput source="timesValue" />
      <TextInput source="icon" />
    </SimpleForm>
  </Create>
);

export default AchievementCreate;
