import React, { PureComponent } from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import dataProvider from '../../providers/dataProvider';
import SessionCompletedChart from './SessionCompletedChart';
import SessionByProgramChart from './SessionByProgramChart';
import isStaff from '../../utils/isStaff';

const styles = {
  card: {
    padding: '50px',
  },
  header: {
    padding: '15px',
  },
};

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '7',
      dashboard: {},
    };
  }

  componentDidMount() {
    this.getDashboardData(7);
  }

  getDashboardData = (value) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      dataProvider('GET_ALL', `dashboard?days=${value}`).then((res) => {
        this.setState({ dashboard: res.data });
      });
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.getDashboardData(value);
  };

  render() {
    const { classes } = this.props;
    const { value, dashboard } = this.state;
    let user = localStorage.getItem('user');
    if (!user) {
      return null;
    }
    user = JSON.parse(user);
    if (!isStaff(user)) {
      return null;
    }
    return (
      <Grid container spacing={24}>
        <Grid item md={12}>
          <Card className={classes.header}>
            <Grid container spacing={24} alignItems="center">
              <Grid item md={3}>
                <Typography variant="headline" align="center">
                  Dashboard
                </Typography>
              </Grid>
              <Grid item md={9}>
                <BottomNavigation
                  value={value}
                  showLabels
                  onChange={this.handleChange}
                  className={classes.root}
                >
                  <BottomNavigationAction label="7 days" value="7" icon={<RestoreIcon />} />
                  <BottomNavigationAction label="30 days" value="30" icon={<RestoreIcon />} />
                  <BottomNavigationAction label="60 days" value="60" icon={<RestoreIcon />} />
                </BottomNavigation>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item md={12}>
          <Grid container spacing={24}>
            <Grid item md={3}>
              <Card className={classes.card}>
                <Typography variant="display2" align="center">
                  {dashboard.newUsers}
                </Typography>
                <Typography variant="subheading" align="center">
                  New users
                </Typography>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card className={classes.card}>
                <Typography variant="display2" align="center">
                  {dashboard.startedSessions}
                </Typography>
                <Typography variant="subheading" align="center">
                  Sessions Started
                </Typography>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card className={classes.card}>
                <Typography variant="display2" align="center">
                  {dashboard.completedSession}
                </Typography>
                <Typography variant="subheading" align="center">
                  Sessions Completed
                </Typography>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card className={classes.card}>
                <Typography variant="display2" align="center">
                  {dashboard.scheduledSessions}
                </Typography>
                <Typography variant="subheading" align="center">
                  Scheduled Sessions
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={24}>
            <Grid item md={9}>
              <Card className={classes.card}>
                <Typography variant="title" paragraph>
                  Sessions Completed
                </Typography>
                {dashboard.sessionsByDay ? (
                  <SessionCompletedChart data={dashboard.sessionsByDay} />
                ) : null}
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card className={classes.card}>
                <Typography variant="title" paragraph align="center">
                  Sessions Completed
                </Typography>
                {dashboard.sessionsByProgram ? (
                  <SessionByProgramChart data={dashboard.sessionsByProgram} />
                ) : null}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Dashboard);
