import React, { memo, useEffect, useState, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePagination from '@material-ui/core/TablePagination';
import { format } from 'date-fns';
import dataProvider from '../../../providers/dataProvider';
import { paymentTypeById } from '../../../constants/paymentType';
import paymentStatus, { paymentStatusById } from '../../../constants/paymentStatus';
import EmptyBlock from './EmptyBlock';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import UpdatePaymentStatusModal from './UpdatePaymentStatusModal';

const fetchPurchasedProducts = ({ page, perPage = 10, userId }) => {
  return dataProvider('GET_LIST', `admin/users/products`, {
    pagination: { page: page + 1, perPage },
    sort: {},
    additionalFields: { userId },
  });
};

const PurchasedProducts = ({ userId }) => {
  const [isLoading, changeIsLoading] = useState(false);

  const [products, changeProduct] = useState([]);
  const [page, changePage] = useState(0);
  const [total, changeTotal] = useState(0);
  const [perPage, changePerPage] = useState(10);

  const fetchData = useCallback(async () => {
    changeIsLoading(true);
    const result = await fetchPurchasedProducts({ page, perPage, userId });

    changeIsLoading(false);
    changeProduct(result.data.products);
    changeTotal(result.data.total);
  }, [page, perPage, userId]);
  const handleChangeRowsPerPage = useCallback(({ target: { value } }) => {
    changePerPage(value);
  }, []);
  const handleChangePage = useCallback(({ target: { value } }) => {
    changePage(value);
  }, []);

  const [isOpenUpdatePaymentStatus, changeIsOpenUpdatePaymentStatus] = useState(false);
  const [updatePaymentStatusData, changeUpdatePaymentStatusData] = useState({});
  const handleOpenUpdatePaymentStatus = useCallback(({ id, status, type }) => {
    changeIsOpenUpdatePaymentStatus(true);
    changeUpdatePaymentStatusData({ id, paymentStatus: status, paymentType: type });
  }, []);
  const handleCloseUpdatePaymentStatus = useCallback(() => {
    changeIsOpenUpdatePaymentStatus(false);
    changeUpdatePaymentStatusData({});
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Paid Status</TableCell>
            <TableCell>Purchased Date</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.length ? (
            products.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{paymentTypeById[item.paymentType]}</TableCell>
                <TableCell>
                  {`${paymentStatusById[item.paymentStatus]}${
                    item.paymentStatus === paymentStatus.partialPaid.id
                      ? ` (${item.paidCount})`
                      : ''
                  }`}
                </TableCell>
                <TableCell>{format(item.createdAt, 'MM/DD/YYYY')}</TableCell>
                <TableCell>
                  <DotsMenu
                    onChange={() =>
                      handleOpenUpdatePaymentStatus({
                        id: item.id,
                        status: item.paymentStatus,
                        type: item.paymentType,
                      })
                    }
                    options={['Change Payment Status']}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="4">
                <EmptyBlock
                  title="Customer has not purchased any products yet."
                  body="All purchased products will appear here."
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        page={page}
        rowsPerPage={perPage}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
      <UpdatePaymentStatusModal
        isOpen={isOpenUpdatePaymentStatus}
        selectedData={updatePaymentStatusData}
        handleClose={handleCloseUpdatePaymentStatus}
      />
    </>
  );
};

export default memo(PurchasedProducts);
