import dataProvider from '../providers/dataProvider';

function uploadService(data, progressFunc = null) {
  return dataProvider('UPLOAD_FILE', 'file', {
    data,
    onUploadProgress: progressFunc,
  });
}

export default uploadService;
