import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { crudUpdate, ImageInput, required, translate, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { reduxForm } from 'redux-form';
import ImgField from '../../../components/ImgField/ImgField';

class EditPasswordModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleSubmit, children, onSave } = this.props;
    return (
      <>
        <div onClick={this.handleClickOpen} aria-hidden="true">
          {children}
        </div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Edit Avatar
          </DialogTitle>
          <form onSubmit={handleSubmit(onSave)}>
            <DialogContent>
              <ImageInput source="picture" label="Picture" validate={required()}>
                <ImgField source="picture" title="Picture" addLabel width="270" height="270" />
              </ImageInput>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>close</Button>
              <Button variant="contained" type="submit" onClick={this.handleClose} color="primary">
                Save changes
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

const updateSession = (values, redirectTo) => {
  return crudUpdate('sessions', values.id, values, values, '/sessions', redirectTo);
};

const enhance = compose(
  translate,
  reduxForm({
    form: 'EditAdminAvatar',
    initialValues: {},
  }),
  connect(undefined, { updateSession, showNotification }),
);

export default enhance(EditPasswordModal);
