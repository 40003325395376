import React, { PureComponent } from 'react';
import { Toolbar, DeleteButton, SaveButton } from 'react-admin';

import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import RemoveButton from '../../../components/RemoveButton/RemoveButton';

class EditToolbarCustomer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  deleteExercise = () => {
    this.setState({ isOpen: true });
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  render() {
    return (
      <>
        <Toolbar {...this.props} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SaveButton label="save" redirect="list" submitOnEnter={false} />
          <RemoveButton variant="contained" deleteExercise={this.deleteExercise} />
        </Toolbar>
        <DeleteAlertDialog
          title="Delete Customer"
          subtitle="Are you sure you want to delete Customer ?"
          description="Customer will be deleted permanently."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            redirect="/users"
            basePath={this.props.basePath}
            record={this.props.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

export default EditToolbarCustomer;
