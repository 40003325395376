import React, { PureComponent } from 'react';

import { Edit } from 'react-admin';
import { withStyles } from '@material-ui/core';
import EditForm from './ExercisesEditForm';

const styles = {
  root: {
    padding: '0px',
  },
  card: {
    background: 'none',
    boxShadow: 'none',
    padding: '0px',
  },
};

class ExercisesEdit extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Edit {...this.props} className={classes.root}>
        <EditForm />
      </Edit>
    );
  }
}

export default withStyles(styles)(ExercisesEdit);
