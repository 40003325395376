export const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#e4e4e4',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
    padding: '20px',
  },
  avatar: {
    margin: '2.5em 0 0 0',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    height: '50px',
  },
};
