import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';

import { CREATE } from 'react-admin';
import dataProvider from '../../../providers/dataProvider';
import DialogTitleWithCloseIcon from '../../../components/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

class ListProgramsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      selected: [],
      list: [],
      page: 0,
      total: 0,
      perPage: 10,
      fetched: false,
    };
  }

  componentDidUpdate() {
    const { opened } = this.props;
    const { list, fetched } = this.state;
    if (opened && !fetched && !list.length) {
      this.getUserPrograms();
      this.getPrograms();
    }
  }

  handleToggle = (value) => () => {
    const { checked, selected } = this.state;
    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value.id);
      newSelected.push(value);
      this.addProgramToUser(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
      newSelected.splice(currentIndex, 1);
      this.removeProgramFromUser(value.id);
    }
    this.setState(() => ({
      checked: newChecked,
      selected: newSelected,
    }));
  };

  handleAdd = () => {
    this.props.handleAddExercises(this.state.selected);
    this.setState({ selected: [] });
    this.props.handleClose();
  };

  getPrograms = (page = 0, perPage = 10) => {
    dataProvider('GET_LIST', 'programs', {
      pagination: { page: page + 1, perPage },
      sort: {},
    }).then((res) => {
      this.setState(() => ({ list: res.data, total: res.total }));
    });
  };

  getUserPrograms = () => {
    const { userId } = this.props;
    dataProvider('GET_ALL', `admin/users/${userId}/programs`).then((res) => {
      let list = [];
      if (res.data.userPrograms.length) {
        list = res.data.userPrograms.map((item) => item.program.id);
      }
      this.setState(() => ({ checked: list, fetched: true }));
    });
  };

  addProgramToUser = (programId) => {
    dataProvider(CREATE, `programs/${programId}/grant`, {
      data: { userId: this.props.userId },
    }).then((res) => res);
  };

  removeProgramFromUser = (programId) => {
    dataProvider(CREATE, `programs/${programId}/revoke`, {
      data: { userId: this.props.userId },
    }).then((res) => res);
  };

  handleChangePage = (e, page) => {
    this.setState(() => ({ page }));
    this.getPrograms(page, this.state.perPage);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState(() => ({ perPage: e.target.value }));
    this.getPrograms(this.state.page, e.target.value);
  };

  render() {
    const { close, opened } = this.props;
    const { page, total, perPage } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={opened}
      >
        <DialogTitleWithCloseIcon onClose={close}>Program Access</DialogTitleWithCloseIcon>
        <DialogContent>
          {!this.state.list.length ? (
            <p>Exercises are not found</p>
          ) : (
            <List>
              {this.state.list.map((value) => (
                <ListItem key={value.id} dense button onClick={this.handleToggle(value)}>
                  <Checkbox
                    checked={this.state.checked.indexOf(value.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText primary={`${value.name}`} />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            page={page}
            rowsPerPage={perPage}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            onChangePage={this.handleChangePage}
          />
          <Button variant="contained" onClick={close} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ListProgramsModal;
