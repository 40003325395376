import React, { PureComponent } from 'react';

import { Edit } from 'react-admin';
import EditFormAdmin from './EditFormAdmin';

class EditAdmin extends PureComponent {
  render() {
    return (
      <Edit {...this.props}>
        <EditFormAdmin />
      </Edit>
    );
  }
}

export default EditAdmin;
