import paymentType from './paymentType';

const paymentStatus = {
  fullPaid: { id: 0, name: 'Full Paid' },
  partialPaid: { id: 1, name: 'Partial Paid' },
  active: { id: 2, name: 'Active Subscription' },
  canceled: { id: 3, name: 'Canceled Subscription' },
  refunded: { id: 4, name: 'Refunded Product' },
};

export const paymentStatusById = Object.values(paymentStatus).reduce((acc, i) => {
  return { ...acc, [i.id]: i.name };
}, {});

export const paymentStatusAvailableSelectOptions = (type) => {
  const availableStatus = { ...paymentStatus };
  if (type === paymentType.oneTimePayment.id) {
    delete availableStatus.partialPaid;
    delete availableStatus.active;
    delete availableStatus.canceled;
  } else if (type === paymentType.paymentPlan.id) {
    delete availableStatus.active;
    delete availableStatus.canceled;
  } else if (type === paymentType.subscription.id) {
    delete availableStatus.fullPaid;
    delete availableStatus.partialPaid;
  }

  return Object.values(availableStatus).map((i) => ({
    id: i.id,
    name: i.name,
  }));
};

export default paymentStatus;
