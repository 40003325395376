import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  { name: 'Page A', pv: 2400 },
  { name: 'Page B', pv: 1398 },
  { name: 'Page C', pv: 9800 },
  { name: 'Page D', pv: 3908 },
  { name: 'Page E', pv: 4800 },
  { name: 'Page F', pv: 3800 },
  { name: 'Page G', pv: 4300 },
];

class SessionCompletedChart extends Component {
  convertData = () => {
    const { data } = this.props;
    return data.map((item) => ({
      date: item.date,
      sessions: item.count,
    }));
  };

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={this.convertData()} margin={{ top: 5, right: 0, left: -30, bottom: 5 }}>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="sessions" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
SessionCompletedChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SessionCompletedChart;
