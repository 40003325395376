import React, { memo, useCallback } from 'react';
import { EditButton } from 'react-admin';

const OpenEditPage = ({ openEdit }) => {
  const handleOnClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openEdit();
    },
    [openEdit],
  );

  return <EditButton onClick={handleOnClick} />;
};

export default memo(OpenEditPage);
