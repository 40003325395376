import React, { PureComponent } from 'react';
import { SaveButton, SimpleForm } from 'react-admin';
import { reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

class CircuitModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameError: null,
      restFor: 0,
      restForError: null,
    };
  }

  componentDidMount = () => {
    const { circuit } = this.props;
    if (circuit) {
      const { id, name, restFor } = circuit;
      this.setState({
        circuitId: id,
        name: name || '',
        nameError: null,
        restFor: restFor || 0,
        restForError: null,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  onSubmit = () => {
    const { addCircuit, updateCircuit, circuit } = this.props;
    const { circuitId, name, restFor } = this.state;
    // eslint-disable-next-line eqeqeq
    if (!this.validate('name', name) || !(restFor || restFor == '0')) {
      return false;
    }
    const values = { name };
    // eslint-disable-next-line radix
    values.restFor = parseInt(restFor);
    if (circuitId) {
      updateCircuit(circuitId, Object.assign(circuit, values));
    } else {
      addCircuit(values);
    }
    this.close();
  };

  // eslint-disable-next-line class-methods-use-this
  getNumericValue(event) {
    return event.target.value.replace(/[^0-9]/g, '');
  }

  close = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  handleNameChange = (event) => {
    this.handleChange('name', event.target.value);
  };

  handleRestForChange = (event) => {
    const restFor = this.getNumericValue(event);
    this.handleChange('restFor', restFor);
  };

  handleChange = (name, value) => {
    this.validate(name, value);
  };

  validate = (name, value) => {
    let error = null;
    if (!value) {
      error = 'Required';
    }
    this.setState({
      [name]: value,
      [`${name}Error`]: error,
    });
    return !error;
  };

  render() {
    const { open } = this.props;
    const { name, nameError, restFor, restForError } = this.state;
    const { handleSubmit } = this.props;
    const fullWidth = {
      width: '100%',
    };
    return (
      <Dialog
        open={open}
        onClose={this.close}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Circuit</DialogTitle>
        <DialogContent>
          <SimpleForm
            variant="standard"
            id="circuit-form"
            form="circuit-create"
            margin="normal"
            onSubmit={handleSubmit(this.onSubmit)}
            toolbar={null}
          >
            <SanitizedGrid container spacing={8} style={fullWidth}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  required
                  value={name}
                  onChange={this.handleNameChange}
                  autoFocus
                  fullWidth
                  error={!!nameError}
                  helperText={nameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rest for"
                  name="restFor"
                  value={restFor}
                  onChange={this.handleRestForChange}
                  required
                  fullWidth
                  error={!!restForError}
                  helperText={restForError}
                />
              </Grid>
            </SanitizedGrid>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <SaveButton type="submit" form="circuit-form" onClick={handleSubmit(this.onSubmit)} />
          <Button onClick={this.close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

// eslint-disable-next-line no-class-assign
CircuitModal = reduxForm({
  form: 'circuit-form',
})(CircuitModal);

export default CircuitModal;
