import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import roles from '../../../constants/roles';

class CreateAdmin extends Component {
  getRoles = () => {
    return Object.values(roles)
      .filter((item) => item !== 'User')
      .map((item) => ({ id: item, name: item }));
  };

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
          <TextInput source="firstName" label="FirstName" validate={required()} />
          <TextInput source="lastName" label="LastName" validate={required()} />
          <TextInput source="email" label="Email" validate={required()} />
          <TextInput source="position" label="Position or Title" validate={required()} />
          <SelectInput
            source="role"
            choices={this.getRoles()}
            label="Permissions Level"
            validate={required()}
          />
        </SimpleForm>
      </Create>
    );
  }
}

export default CreateAdmin;
