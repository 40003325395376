import React, { PureComponent } from 'react';
import { SaveButton, Toolbar, DeleteButton } from 'react-admin';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import RemoveButton from '../../../components/RemoveButton/RemoveButton';

class EditToolbarAdmin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      record: null,
    };
  }

  deleteExercise = () => {
    this.setState({ isOpen: true });
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  render() {
    return (
      <>
        <Toolbar
          {...this.props}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: 'none',
          }}
        >
          <SaveButton redirect="list" {...this.props} label="save" submitOnEnter={false} />
          <RemoveButton variant="contained" deleteExercise={this.deleteExercise} />
        </Toolbar>
        <DeleteAlertDialog
          title="Delete Admin"
          subtitle="Are you sure you want to delete Admin ?"
          description="Admin will be deleted permanently"
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            redirect="/admins"
            basePath={this.props.basePath}
            record={this.props.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

export default EditToolbarAdmin;
