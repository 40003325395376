const difficulty = [
  { id: 0, name: '-' },
  { id: 1, name: 'Movement' },
  { id: 2, name: 'Attributes' },
  { id: 3, name: 'Supplemental' },
];

export const difficultySelectableOptions = difficulty.filter((i) => i.id !== 0);

export default difficulty;
