import * as React from 'react';
import { Datagrid, List, TextField, FunctionField, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import ExercisesFilter from '../ExercisesFilter/ExercisesFilter';
import ImgField from '../../../components/ImgField/ImgField';
import roles from '../../../constants/roles';

const styles = {
  action: {
    textAlign: 'right',
  },
};

class ExercisesList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      record: null,
    };
  }

  handleAction = (action, record) => {
    if (action && action.toLowerCase() === 'delete') {
      this.setState({ isOpen: true, record });
    }
    if (action && action.toLowerCase() === 'edit') {
      this.props.history.push(`/exercise/${record.id}`);
    }
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  render() {
    const { classes, permissions, ...props } = this.props;
    return (
      <>
        <List
          {...props}
          exporter={false}
          bulkActions={false}
          sort={{}}
          filterDefaultValues={{ order_by: '-createdAt' }}
          filter={{}}
          filters={<ExercisesFilter />}
          title="Exercises List"
        >
          <Datagrid rowClick="show">
            <ImgField source="picture" sortable={false} />
            <TextField source="name" sortable={false} />
            <TextField source="description" sortable={false} />
            <FunctionField
              label="Session Name (Program Name)"
              render={(record) => {
                if (!record.sessionInfo || record.sessionInfo.length === 0) {
                  return '-';
                }
                let result = `${record.sessionInfo[0].sessionName} (${record.sessionInfo[0].programName})`;
                if (record.sessionInfo.length > 1) {
                  result += ` ...(${record.sessionInfo.length})`;
                }
                return result;
              }}
            />
            {permissions === roles.superAdmin ? (
              <FunctionField
                cellClassName={classes.action}
                label=""
                onClick={(e) => {
                  e.stopPropagation();
                }}
                render={(record) => (
                  <DotsMenu
                    onChange={(e) => this.handleAction(e, record)}
                    options={['delete', 'edit']}
                  />
                )}
              />
            ) : null}
          </Datagrid>
        </List>
        <DeleteAlertDialog
          title="Delete Exercise"
          subtitle="Are you sure you want to delete exercise ?"
          description="Exercise will be deleted permanently and removed from all the programs where it appears."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            size="large"
            redirect={() => this.handleCloseModalDelete()}
            basePath={this.props.basePath}
            record={this.state.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

export default withStyles(styles)(ExercisesList);
