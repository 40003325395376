import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core';
import dataProvider from '../../../providers/dataProvider';

const style = {
  field: {
    marginTop: '20px',
  },
  input: {},
};

class ChooseExercisesModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      selected: [],
      list: [],
      selectedCircuit: '',
    };
  }

  componentDidMount() {
    this.getExercise();
  }

  handleChange = (event) => {
    this.setState({ selectedCircuit: event.target.value });
  };

  getExercise = (term = null) => {
    dataProvider('GET_LIST', 'exercise', {
      pagination: { page: 1, perPage: 20 },
      sort: {},
      filter: { q: term },
    }).then((res) => {
      this.setState(() => ({ list: res.data }));
    });
  };

  handleToggle = (value) => () => {
    const { checked, selected } = this.state;
    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value.id);
      newSelected.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newSelected.splice(currentIndex, 1);
    }
    this.setState(() => ({
      checked: newChecked,
      selected: newSelected,
    }));
  };

  handleSearch = (event) => {
    this.getExercise(event.target.value);
  };

  handleAdd = () => {
    this.props.handleAddExercises(this.state.selected, this.state.selectedCircuit);
    this.setState({ selected: [] });
    this.props.handleClose();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          fullWidth
          maxWidth="md"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Exercise Library</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel>Add to circuit</InputLabel>
              <Select fullWidth value={this.state.selectedCircuit} onChange={this.handleChange}>
                <MenuItem value="" />
                {this.props.circuits.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={i + +Date.now()} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Filter by name</InputLabel>
              <Input
                className={classes.input}
                fullWidth
                placeholder="Name"
                onChange={this.handleSearch}
              />
            </FormControl>

            {!this.state.list.length ? (
              <p>Exercises are not found</p>
            ) : (
              <List>
                {this.state.list.map((value) => (
                  <ListItem key={value.id} dense button onClick={this.handleToggle(value)}>
                    <Checkbox
                      checked={this.state.checked.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText primary={`${value.name}`} />
                  </ListItem>
                ))}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleAdd} color="primary">
              Add exercise
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style)(ChooseExercisesModal);
