import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FileInput } from 'react-admin';
import FileFieldCustom from '../../../components/FileFieldCustom/FileFieldCustom';

class IntroScreensComponent extends PureComponent {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      introScreen1: {
        title: value.name,
        text: value.introScreens[0] ? value.introScreens[0].text : '',
      },
      introScreen2: {
        title: value.introScreens[1] ? value.introScreens[1].title : '',
        text: value.introScreens[1] ? value.introScreens[1].text : '',
        video: value.introScreens[1] ? value.introScreens[1].video : '',
      },
    };
  }

  componentDidMount() {
    this.props.handleData(this.converToServerFormat(this.state));
  }

  getValue = (value, name) => {
    return value ? value[name] : '';
  };

  handleChangeFirst = (event) => {
    const newState = {
      introScreen1: { ...this.state.introScreen1, text: event.target.value },
      introScreen2: { ...this.state.introScreen2 },
    };
    this.setState(newState);
    this.props.handleData(this.converToServerFormat(newState));
  };

  handleChangeSecond = (event) => {
    const newState = {
      introScreen1: { ...this.state.introScreen1 },
      introScreen2: {
        ...this.state.introScreen2,
        [event.target.name]: event.target.value,
      },
    };
    this.setState(newState);
    this.props.handleData(this.converToServerFormat(newState));
  };

  converToServerFormat = (data) => {
    return [{ ...data.introScreen1 }, { ...data.introScreen2 }];
  };

  handleFile = (event) => {
    let newState;
    if (event.rawFile) {
      newState = {
        introScreen1: { ...this.state.introScreen1 },
        introScreen2: { ...this.state.introScreen2, video: event },
      };
    } else {
      newState = {
        introScreen1: { ...this.state.introScreen1 },
        introScreen2: { ...this.state.introScreen2, video: this.state.video },
      };
    }

    this.setState(newState);
    this.props.handleData(this.converToServerFormat(newState));
  };

  render() {
    const { value } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <TextField
            placeholder={`Welcome to ${this.state.introScreen1.title}`}
            disabled
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="introScreen1.text"
            placeholder="Text"
            fullWidth
            value={this.state.introScreen1.text}
            onChange={this.handleChangeFirst}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            placeholder="Title"
            name="title"
            fullWidth
            value={this.state.introScreen2.title}
            onChange={this.handleChangeSecond}
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FileInput
            onBlur={this.handleFile}
            onDrop={this.handleFile}
            defaultValue={this.state.introScreen2.video || undefined}
            format={(v) => (typeof v === 'string' ? { picture: v } : v)}
            source="video"
            label="screen video"
            accept="video/mp4"
          >
            <FileFieldCustom
              source="src"
              title="fileName"
              isSubtitle
              values={value}
              checkedResource="programs"
              checkedName="introScreens[1].video"
            />
          </FileInput>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="text"
            placeholder="Text"
            fullWidth
            value={this.state.introScreen2.text}
            onChange={this.handleChangeSecond}
          />
        </Grid>
      </Grid>
    );
  }
}
export default IntroScreensComponent;
