import React, { PureComponent } from 'react';

import { Create, SimpleForm, TextInput, required } from 'react-admin';

class CreateCustomer extends PureComponent {
  render() {
    return (
      <>
        <Create {...this.props}>
          <SimpleForm defaultValue={{ role: 'User' }} redirect="list" submitOnEnter={false}>
            <TextInput source="firstName" label="First Name" validate={required()} />
            <TextInput source="lastName" label="Last Name" validate={required()} />
            <TextInput fullWidth source="email" label="Email Address" validate={required()} />
          </SimpleForm>
        </Create>
      </>
    );
  }
}

export default CreateCustomer;
