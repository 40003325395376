// eslint-disable-next-line no-unused-vars
import React, { PureComponent } from 'react';
import { redirectToAuth } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';

class Logout extends PureComponent {
  componentDidMount() {
    Session.signOut().finally(() => {
      redirectToAuth({ redirectBack: false });
    });
  }

  render() {
    return null;
  }
}

export default Logout;
