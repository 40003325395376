import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  publish: {
    float: 'right',
  },
});

class PublishProgram extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  isValid = () => {
    const { isSessions } = this.props;
    return !isSessions;
  };

  render() {
    const tooltipText = this.isValid()
      ? `To Publish a Program Please Provide the Required Content`
      : '';
    const { formData, classes } = this.props;
    return (
      <>
        <Tooltip title={tooltipText} placement="left">
          <span className={classes.publish}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleClickOpen()}
              disabled={this.isValid()}
            >
              {formData.isPublic ? 'Unpublish' : 'Publish'}
            </Button>
          </span>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {formData.isPublic ? 'Hide' : 'Show'} Program
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              <b>Are you sure you want to {formData.isPublic ? 'hide' : 'show'} program?</b>
            </Typography>
            <Typography gutterBottom>
              If you {formData.isPublic ? 'hide' : 'show'} program,
              {formData.isPublic
                ? 'it will no longer be visible to GMB customers.'
                : 'it will be visible to GMB customers.'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.props.handlePublic(formData);
                this.handleClose();
              }}
              variant="contained"
              color="primary"
            >
              {formData.isPublic ? 'Hide' : 'Show'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

PublishProgram.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  isSessions: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PublishProgram);
