import React, { memo, useCallback, useMemo, useState } from 'react';
import { withStyles, TextField, Paper, Typography } from '@material-ui/core';
import SessionItem from './SessionItem';
import globalStyles from './styles';

const styles = {
  wrapper: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    padding: '0 10px 0 0',
    background: '#fff',
  },
  header: {
    boxSizing: 'border-box',
    width: '100%',
    height: 50,
  },
  titleWrapper: {
    ...globalStyles.itemWrapper,
    marginTop: 10,
    backgroundColor: 'lightGrey',
    height: 70,
  },
  list: {
    ...globalStyles.list,
    marginTop: 10,
  },
};

const SessionColumn = ({ allSessions, nextTrackSessionId, classes }) => {
  const [filterInput, changeFilterInput] = useState('');
  const handleChangeFilterInput = useCallback(({ target: { value } }) => {
    changeFilterInput(value);
  }, []);

  const filteredSessions = useMemo(() => {
    if (!allSessions.length) {
      return [];
    }

    return allSessions.filter((session) =>
      session.name?.toLowerCase().includes(filterInput.toLowerCase()),
    );
  }, [allSessions, filterInput]);

  return (
    <div className={classes.wrapper}>
      <TextField
        label="Filter Sessions"
        className={classes.header}
        value={filterInput}
        onChange={handleChangeFilterInput}
      />
      <Paper className={classes.titleWrapper} elevation={1}>
        <Typography>Available Sessions</Typography>
      </Paper>
      <div className={classes.list}>
        {filteredSessions.map((i) => (
          <SessionItem key={i.id} item={i} nextTrackSessionId={nextTrackSessionId} />
        ))}
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(SessionColumn));
