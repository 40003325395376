const styles = {
  itemWrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    width: 230,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    width: '100%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  draggable: {
    cursor: 'move',
  },
};

export default styles;
