import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput, required } from 'react-admin';
import { AchievementType, TriggerType } from './achievementConstants';

const validateSVG = (value) => {
  if (!value) return undefined;

  // Basic SVG validation
  const svgRegex = /<svg[\s\S]*<\/svg>/i;
  if (!svgRegex.test(value)) {
    return 'Invalid SVG format';
  }

  return undefined;
};

const AchievementEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" multiline />
        <NumberInput source="programId" />
        <SelectInput
          source="type"
          choices={Object.entries(AchievementType).map(([id, name]) => ({ id, name }))}
          value={props.type}
        />
        <SelectInput
          source="triggerType"
          choices={Object.entries(TriggerType).map(([id, name]) => ({ id, name }))}
        />
        <NumberInput source="timesValue" />
        <TextInput source="icon" label="Achievement Icon (SVG)" multiline validate={validateSVG} />
      </SimpleForm>
    </Edit>
  );
};

export default AchievementEdit;
