import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import DialogTitleWithCloseIcon from '../DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

class DeleteAlertDialog extends React.PureComponent {
  render() {
    const { title, subtitle, description } = this.props;
    return (
      <div>
        <Dialog
          maxWidth="md"
          style={{ textAlign: 'center' }}
          open={this.props.open}
          onClose={this.props.handleClose}
        >
          <DialogTitleWithCloseIcon onClose={this.props.handleClose}>
            {title}
          </DialogTitleWithCloseIcon>
          <DialogContent>
            <Typography variant="subheading">
              <strong>{subtitle}</strong>
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>{this.props.children}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DeleteAlertDialog;
