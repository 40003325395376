import React, { Component } from 'react';
import { FileField } from 'react-admin';
import UploadVideo from '../UploadVideo/UploadVideo';

const getUrlForDownload = (data) => {
  if (data.muxHlsUrl) {
    return data.muxHlsUrl;
  }
  if (data.url) {
    return data.url;
  }
  if (data.rawFile) {
    return data.rawFile.preview;
  }
  return '#';
};

class FileFieldCustom extends Component {
  getVideoName = (data) => {
    if (data.originalName) {
      return data.originalName;
    }
    if (data.rawFile) {
      return data.rawFile.name;
    }
    return 'unknown';
  };

  render() {
    const { record, checkedName, values, isSubtitle, checkedResource } = this.props;
    return (
      <>
        <FileField source="video" />
        <a target="_blank" href={getUrlForDownload(record)} rel="noreferrer">
          {this.getVideoName(record)}
        </a>
        {isSubtitle && !record.rawFile ? (
          <UploadVideo
            checkedResource={checkedResource}
            checkedName={checkedName}
            record={values}
          />
        ) : null}
      </>
    );
  }
}

export default FileFieldCustom;
