import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import DialogTitleWithCloseIcon from '../../../components/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const styles = {
  actions: {
    justifyContent: 'center',
  },
};

class ResetPasswordModal extends PureComponent {
  render() {
    const { classes, handleClose } = this.props;
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
      >
        <DialogTitleWithCloseIcon onClose={handleClose} align="center">
          Reset Password
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <Typography gutterBottom variant="subheading" align="center">
            Are you sure you want to reset customer password.
          </Typography>
          <Typography gutterBottom align="center">
            Customer will receive an email where they can update their password.
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.props.handleResetPassword} color="primary" variant="contained">
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ResetPasswordModal);
