import React, { memo, useState, useCallback, Fragment, useEffect } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  BooleanField,
  TextField,
  FunctionField,
  DeleteButton,
  CardActions,
} from 'react-admin';
import { withStyles, Button, LinearProgress, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import styles from './styles';
import roles from '../../constants/roles';
import CreateOrEditQuestionModal from './components/CreateOrEditQuestion';
import DotsMenu from '../../components/DotsMenu/DotsMenu';
import DeleteAlertDialog from '../../components/DeleteAlertDialog/DeleteAlertDialog';
import QuestionnairesFilter from './components/QuestionnairesFilter';
import { trackType } from '../Programs/ShowProgram/ProgramTrack/constants';

const QuestionnairesActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  handleCreateQuestion,
  classes,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <Button variant="contained" size="small" color="primary" onClick={handleCreateQuestion}>
      <Add className={classes.icon} />
      Add Question
    </Button>
  </CardActions>
);

const Questions = ({ classes, permissions, ...props }) => {
  const { basePath, resource } = props;
  const [isLoading, changeIsLoading] = useState(false);
  const [deleteQuestion, setDeleteQuestion] = useState(null);
  const [isOpenCreateOrEditQuestionModal, setIsOpenCreateOrEditQuestionModal] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(null);

  useEffect(() => {
    if (!isOpenCreateOrEditQuestionModal) {
      setEditQuestionId(null);
    }
  }, [isOpenCreateOrEditQuestionModal]);

  const handleCreateQuestion = useCallback(() => {
    setIsOpenCreateOrEditQuestionModal(!isOpenCreateOrEditQuestionModal);
  }, [isOpenCreateOrEditQuestionModal]);

  const handleAction = useCallback((action, record) => {
    if (action && action.toLowerCase() === 'delete') {
      setDeleteQuestion(record);
    }
    if (action && action.toLowerCase() === 'edit') {
      setEditQuestionId(record.id);
      setIsOpenCreateOrEditQuestionModal(true);
    }
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setDeleteQuestion(null);
  }, []);

  const handleChangeLoading = useCallback((loading) => {
    changeIsLoading(loading);
  }, []);

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <List
          {...props}
          filters={<QuestionnairesFilter />}
          actions={
            <QuestionnairesActions handleCreateQuestion={handleCreateQuestion} classes={classes} />
          }
          exporter={false}
          bulkActionButtons={false}
        >
          <Datagrid>
            <NumberField source="order" />
            <TextField source="title" sortable={false} />
            <TextField source="description" sortable={false} />
            <BooleanField sortable={false} source="multiSelection" />
            <FunctionField
              label="Type"
              render={(record) => (record.type === trackType ? 'Track' : 'Common')}
            />
            <FunctionField
              label="Programs"
              className={classes.textCenter}
              render={(record) =>
                record.programs?.length ? record.programs.map((p) => p.name).join(',') : '-'
              }
            />
            <FunctionField
              label="Answers"
              render={(record) =>
                record.answers?.map((answer) => (
                  <Fragment key={`id${answer.id}`}>
                    <Typography>
                      {answer.order}. {answer.title}
                    </Typography>
                    <Typography variant="caption">{answer.value}</Typography>
                  </Fragment>
                ))
              }
            />
            {permissions === roles.superAdmin ? (
              <FunctionField
                label=""
                onClick={(e) => {
                  e.stopPropagation();
                }}
                render={(record) => (
                  <DotsMenu
                    onChange={(e) => handleAction(e, record)}
                    options={['edit', 'delete']}
                  />
                )}
              />
            ) : null}
          </Datagrid>
        </List>
      )}
      {isOpenCreateOrEditQuestionModal && (
        <CreateOrEditQuestionModal
          handleChangeLoading={handleChangeLoading}
          handleCreateQuestion={handleCreateQuestion}
          editQuestionId={editQuestionId}
          basePath={basePath}
        />
      )}
      <DeleteAlertDialog
        title="Delete Question"
        subtitle="Are you sure you want to delete question?"
        description="If you delete question, all related answers will be deleted."
        record={deleteQuestion}
        open={!!deleteQuestion}
        handleClose={handleCloseModalDelete}
      >
        <DeleteButton
          size="large"
          redirect={() => handleCloseModalDelete()}
          basePath={basePath}
          record={deleteQuestion}
          resource={resource}
        />
      </DeleteAlertDialog>
    </div>
  );
};

export default memo(withStyles(styles)(Questions));
