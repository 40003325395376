import paymentType from '../constants/paymentType';

const removeUnnecessaryProductFields = (data) => {
  const newData = { ...data };
  switch (newData.paymentType) {
    case paymentType.oneTimePayment.id: {
      newData.paymentCount = null;
      newData.subscriptionBillingPeriod = null;
      break;
    }
    case paymentType.paymentPlan.id: {
      newData.subscriptionBillingPeriod = null;
      break;
    }
    case paymentType.subscription.id: {
      newData.paymentCount = null;
      break;
    }
    default: {
      // empty
    }
  }

  return newData;
};

export default removeUnnecessaryProductFields;
