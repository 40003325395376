import React, { PureComponent } from 'react';
import { Show, TabbedShowLayout, Tab, SimpleShowLayout } from 'react-admin';
import { withStyles } from '@material-ui/core';
import ProgramHistory from './ProgramHistory';
import WorkoutHistory from './WorkoutHistory';
import OnbordingQuestions from './OnbordingQuestions';
import PurchasedProducts from './PurchasedProducts';
import ShowGrid from './ShowGrid';

const styles = () => ({
  tabContent: {
    padding: '30px 0 0 0',
  },
  tab: {
    flex: '1 1 auto',
    textAlign: 'center',
    width: '100px',
  },
});

class ShowCustomer extends PureComponent {
  render() {
    const { match, classes, permissions, ...props } = this.props;
    return (
      <>
        <Show {...props} actions={null}>
          <SimpleShowLayout>
            <ShowGrid history={this.props.history} permissions={permissions} />
            <TabbedShowLayout>
              <Tab label="Program History" className={classes.tab}>
                <ProgramHistory userId={match.params.id} className={classes.tabContent} />
              </Tab>
              <Tab label="Workout History" className={classes.tab}>
                <WorkoutHistory userId={match.params.id} className={classes.tabContent} />
              </Tab>
              <Tab label="Onboarding Question Answers" className={classes.tab}>
                <OnbordingQuestions userId={match.params.id} className={classes.tabContent} />
              </Tab>
              <Tab label="Purchased Products" className={classes.tab}>
                <PurchasedProducts userId={match.params.id} className={classes.tabContent} />
              </Tab>
            </TabbedShowLayout>
          </SimpleShowLayout>
        </Show>
      </>
    );
  }
}

export default withStyles(styles)(ShowCustomer);
