import React, { PureComponent } from 'react';
import { List, Datagrid, TextField, FunctionField, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import FilterCustomer from '../../Customers/FilterCustomer/FilterCustomer';

const styles = {
  action: {
    textAlign: 'right',
  },
};

class ListAdmins extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      record: null,
    };
  }

  handleAction = (action, record) => {
    if (action && action.toLowerCase() === 'delete') {
      this.setState({ isOpen: true, record });
    }
    if (action && action.toLowerCase() === 'edit') {
      this.props.history.push(`/admins/${record.id}`);
    }
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <>
        <List
          {...props}
          title="Customers"
          exporter={false}
          bulkActions={false}
          sort={{}}
          filter={{}}
          filters={<FilterCustomer />}
          filterDefaultValues={{
            role: 'Coach,Admin,SuperAdmin',
            order_by: '-createdAt',
          }}
        >
          <Datagrid rowClick="show">
            <FunctionField
              label="Name"
              render={(record) => {
                return `${record.firstName} ${record.lastName}`;
              }}
            />
            <TextField source="email" sortable={false} />
            <TextField source="position" sortable={false} />
            <TextField source="role" label="Permission Level" sortable={false} />

            <FunctionField
              cellClassName={classes.action}
              label=""
              onClick={(e) => {
                e.stopPropagation();
              }}
              render={(record) => (
                <DotsMenu
                  onChange={(e) => this.handleAction(e, record)}
                  options={['delete', 'edit']}
                />
              )}
            />
          </Datagrid>
        </List>
        <DeleteAlertDialog
          title="Delete admin"
          subtitle="Are you sure you want to delete admin ?"
          description="Admin will be deleted permanently."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            size="large"
            redirect={() => this.handleCloseModalDelete()}
            basePath={this.props.basePath}
            record={this.state.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

export default withStyles(styles)(ListAdmins);
