import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Show, SimpleShowLayout } from 'react-admin';
import GridProfile from './GridProfile';

class Profile extends PureComponent {
  getUserId = () => {
    const user = localStorage.getItem('user');
    return JSON.parse(user).id.toString();
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { match, staticContext, ...props } = this.props;
    return (
      <Show {...props} id={this.getUserId()} resource="users" basePath="/users">
        <SimpleShowLayout>
          <GridProfile />
        </SimpleShowLayout>
      </Show>
    );
  }
}

export default withRouter(Profile);
