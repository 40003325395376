import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  ImageInput,
  TextInput,
  NumberInput,
  LongTextInput,
  ImageField,
  crudCreate,
  Toolbar,
  SaveButton,
  required,
  SelectArrayInput,
  GET_MANY,
  SelectInput,
} from 'react-admin';
import { connect } from 'react-redux';
import dataProvider from '../../providers/dataProvider';
import { paymentTypeSelectOptions } from '../../constants/paymentType';
import uploadService from '../../utils/upload';
import removeUnnecessaryProductFields from '../../utils/removeUnnecessaryProductFields';
import Loading from '../../components/Loading/Loading';
import PaymentTypeAdditionalField from './components/PaymentTypeAdditionalField';

const saveProgram = (values, basePath) => {
  return crudCreate('admin/products', values, basePath, '/admin/products');
};

const uploadImg = (img) => {
  const formData = new FormData();
  formData.append('file', img.rawFile);
  return uploadService(formData);
};

/* eslint-disable no-unused-vars */
const ProductCreateToolbar = ({
  handleSubmitWithRedirect,
  saveProgram: innerSaveProgram,
  ...props
}) => {
  /* eslint-enable no-unused-vars */
  const [isLoading, changeIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    const { basePath, handleSubmit, redirect } = props;
    return handleSubmit((values) => {
      changeIsLoading(true);
      uploadImg(values.picture)
        .then((res) => {
          const newData = removeUnnecessaryProductFields({ ...values, picture: res.data.fileName });
          innerSaveProgram(newData, basePath, redirect);
        })
        .finally(() => {
          changeIsLoading(false);
        });
    });
  }, [props, innerSaveProgram]);

  return (
    <>
      <Loading open={isLoading} />
      <Toolbar {...props}>
        <SaveButton redirect="list" handleSubmitWithRedirect={handleClick} {...props} />
      </Toolbar>
    </>
  );
};
const ProgramCreateToolbarConnect = connect(null, { saveProgram })(ProductCreateToolbar);

const CreateProduct = (props) => {
  const [options, changeOptions] = useState([]);
  useEffect(() => {
    dataProvider(GET_MANY, 'admin/products/programs').then((res) => changeOptions(res.data));
  }, []);

  return (
    <Create {...props}>
      <SimpleForm toolbar={<ProgramCreateToolbarConnect />} submitOnEnter={false}>
        <TextInput source="name" label="Product Name" validate={required()} />
        <TextInput source="link" label="Checkout Link" />
        <TextInput source="cancelUrl" label="Cancel URL" />
        <TextInput source="externalProductId" label="Product Id" />
        <SelectInput source="paymentType" choices={paymentTypeSelectOptions} />
        <NumberInput source="price" label="Price, $" validate={required()} />
        <PaymentTypeAdditionalField />
        <TextInput
          source="stripePriceId"
          label="Stripe Price Id"
          helperText={
            <span>
              It&apos;s required for stripe products
              <br />
              if isPaymentPlan selected, this should be the subscription stripe price id
            </span>
          }
        />
        <NumberInput source="upsellPrice" label="Upsell Price, $" />
        <TextInput source="upsellStripePriceId" label="Upsell Stripe Price Id" />
        {options.length ? (
          <SelectArrayInput source="programs" choices={options} validate={required()} />
        ) : null}
        <LongTextInput source="description" label="Description" validate={required()} />
        <ImageInput
          source="picture"
          label="Add Product Photo"
          accept="image/*"
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default memo(CreateProduct);
