import React, { memo, useState, useCallback, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import { flatten } from 'lodash';
import { Done, Close, Add } from '@material-ui/icons';
import { defaultTrackType } from './constants';

/**
 * Remove element from array but keep indexes
 * @param {*} arr
 */
const removeElFromArray = (arr, index) => {
  const copy = [...arr];
  copy[index] = null;
  return copy;
};

const generateAnswers = (reducedQuestionsArr, answerTemplate) => {
  const currentQuestionAnswers = [];

  reducedQuestionsArr.forEach((question, index) => {
    // null means that answers for this question has already created
    if (!question) {
      return;
    }

    question.answers.forEach((answer) => {
      const templateCopy = [...answerTemplate];
      templateCopy[index] = answer.value;
      let values = [];

      // Check if answer template still contain gaps
      if (templateCopy.some((e) => !e)) {
        // Fill gaps in template recursively
        values = generateAnswers(removeElFromArray(reducedQuestionsArr, index), templateCopy);
      } else {
        currentQuestionAnswers.push(templateCopy.join(''));
      }

      // ignore duplicates
      values.forEach((val) => {
        if (!currentQuestionAnswers.includes(val)) {
          currentQuestionAnswers.push(val);
        }
      });
    });
  });

  return currentQuestionAnswers;
};

const flattenAnswers = (tracks) => {
  const answers = tracks.map((t) =>
    t.answers.map((a) => ({
      value: a,
      programTrackId: t.id,
    })),
  );

  return flatten(answers) || [];
};

const TracksAnswers = ({ tracks, removeAnswer, addNewAnswer, questions = [] }) => {
  const [answersList, setAnswersList] = useState([]);
  const [newAnswer, setNewAnswer] = useState();
  const [generatedAnswers] = useState(generateAnswers(questions, new Array(questions.length)));

  useEffect(() => {
    if (tracks) {
      setAnswersList(flattenAnswers(tracks));
    }
  }, [tracks]);

  const handleChangeTrack = useCallback(
    (event) => {
      setNewAnswer({
        ...newAnswer,
        programTrackId: Number(event.target.value),
      });
    },
    [newAnswer],
  );

  const handleChangeValue = useCallback(
    (event) => {
      setNewAnswer({
        ...newAnswer,
        value: event.target.value,
      });
    },
    [newAnswer],
  );

  const handleSaveAnswer = useCallback(() => {
    addNewAnswer(newAnswer);
    setNewAnswer();
  }, [newAnswer, addNewAnswer]);

  const decline = useCallback(() => {
    setNewAnswer();
  }, []);

  const handleNewAnswer = useCallback(() => {
    setNewAnswer({
      value: '',
      programTrackId: '',
    });
  }, []);

  return (
    <>
      <>
        {answersList.map((answer, i) => {
          const track = tracks.find((t) => t.id === answer.programTrackId);
          return (
            <Typography component="p" key={`${answer.value}${i + 1}`}>
              <span>{answer.value}: </span>
              <span>{track?.name}</span>
              <IconButton
                color="secondary"
                onClick={() => removeAnswer(answer.programTrackId, answer.value)}
              >
                <Close />
              </IconButton>
            </Typography>
          );
        })}
      </>
      {!newAnswer && (
        <Button variant="contained" color="primary" onClick={handleNewAnswer}>
          <Add />
          Add Track answer
        </Button>
      )}
      {newAnswer && (
        <>
          <FormControl style={{ width: 100, marginRight: 10 }}>
            <InputLabel htmlFor="answers">Answer</InputLabel>
            <Select
              value={newAnswer.value}
              onChange={handleChangeValue}
              inputProps={{ id: 'answers' }}
            >
              {generatedAnswers.map((answer) => (
                <MenuItem value={answer} key={answer}>
                  {answer}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: 150 }}>
            <InputLabel htmlFor="track">Track</InputLabel>
            <Select
              value={newAnswer.programTrackId}
              onChange={handleChangeTrack}
              inputProps={{ id: 'track' }}
            >
              {tracks
                .filter((track) => track.type !== defaultTrackType)
                .map((track) => (
                  <MenuItem value={track.id} key={`id${track.id}`}>
                    {track.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <IconButton color="secondary" onClick={decline}>
            <Close />
          </IconButton>
          <IconButton color="primary" onClick={handleSaveAnswer} disabled={!newAnswer.value}>
            <Done />
          </IconButton>
        </>
      )}
    </>
  );
};

export default memo(TracksAnswers);
