import React, { PureComponent } from 'react';
import {
  SimpleForm,
  TextInput,
  ImageInput,
  FileInput,
  LongTextInput,
  BooleanInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
  SimpleShowLayout,
  required,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { change } from 'redux-form';
import { withStyles } from '@material-ui/core';
import ImgField from '../../../components/ImgField/ImgField';
import CueImagesList from '../../../components/CueImagesList/CueImagesList';
import FileFieldCustom from '../../../components/FileFieldCustom/FileFieldCustom';
import ExercisesUpdateToolbarConnect from './ExercisesEditToolbar';

const styles = {
  simpleShowLayout: {
    width: 'inherit',
    marginBottom: '20px',
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  },
  input: {
    width: '100%',
  },
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

class EditForm extends PureComponent {
  render() {
    const { record, classes, basePath, ...props } = this.props;
    return (
      <SimpleForm
        basePath={basePath}
        defaultValue={{
          ...record,
          newVideo: record.video,
          newFullVideo: record.fullVideo,
          type: record.type === 1,
        }}
        toolbar={<ExercisesUpdateToolbarConnect />}
        record={record}
        {...props}
      >
        <SimpleShowLayout className={classes.simpleShowLayout}>
          <TextInput source="name" className={classes.input} validate={required()} />
          <ImageInput
            validate={required()}
            className={classes.inlineBlock}
            source="picture"
            format={(v) => (typeof v === 'string' ? { picture: v } : v)}
            label="Exercise Photo"
            accept="image/*"
          >
            <ImgField width="100" height="100" source="picture" />
          </ImageInput>

          <FileInput
            validate={required()}
            className={classes.inlineBlock}
            source="newVideo"
            label="Demo video"
            accept="video/mp4"
          >
            <FileFieldCustom
              source="video"
              isSubtitle
              values={record}
              checkedName="video"
              checkedResource="exercise"
            />
          </FileInput>

          <LongTextInput source="description" label="Description" validate={required()} />

          <BooleanInput label="Ask user to switch sides and repeat exercise" source="forEachSide" />
          <BooleanInput
            label="Use repetition counter instead of timer"
            source="type"
            parse={(v) => {
              if (v) {
                return 1;
              }
              return 2;
            }}
            format={(v) => {
              if (typeof v === 'number') {
                return v === 1;
              }
              return v;
            }}
          />
          <BooleanInput label="Hide done button in timer" source="doneButton" />
        </SimpleShowLayout>

        <SimpleShowLayout className={classes.simpleShowLayout}>
          <>
            <Typography variant="title" component="h4">
              Tutorial video
            </Typography>
            <Typography component="span">
              Longest version of the video should be uploaded here. If no video is uploaded in this
              session, the same video uploaded above will be shown.
            </Typography>
          </>
          <FileInput source="newFullVideo" label="" accept="video/mp4">
            <FileFieldCustom
              source="fullVideo"
              isSubtitle
              values={record}
              checkedName="fullVideo"
              checkedResource="exercise"
            />
          </FileInput>
        </SimpleShowLayout>
        <SimpleShowLayout className={classes.simpleShowLayout}>
          <FormDataConsumer>
            {({ formData, dispatch }) => {
              if (formData && formData.cueImages) {
                return (
                  <CueImagesList
                    source="cueImages"
                    values={formData.cueImages}
                    handleData={(value) => {
                      dispatch(change(REDUX_FORM_NAME, 'cueImages', value));
                      dispatch(change(REDUX_FORM_NAME, 'imagesForCue', null));
                    }}
                  />
                );
              }
              return null;
            }}
          </FormDataConsumer>
        </SimpleShowLayout>
        <SimpleShowLayout className={classes.simpleShowLayout}>
          <FormDataConsumer>
            {(data) => {
              if (data.formData && data.formData.tips) {
                return (
                  <>
                    <Typography variant="title" component="h4">
                      On screen tips
                    </Typography>
                    <Typography component="span">
                      These tips appear in timer and repetition counter screens to help guide user
                      as their doing the exercise.
                    </Typography>
                    <LongTextInput source="tip1" defaultValue={data.formData.tips[0]} label="" />
                    <LongTextInput source="tip2" defaultValue={data.formData.tips[1]} label="" />
                    <LongTextInput source="tip3" defaultValue={data.formData.tips[2]} label="" />
                  </>
                );
              }
              return null;
            }}
          </FormDataConsumer>
        </SimpleShowLayout>
      </SimpleForm>
    );
  }
}

export default withStyles(styles)(EditForm);
