const styles = {
  wrapper: {},
  loader: {
    textAlign: 'center',
    padding: '100px 0',
    fontSize: 20,
  },
  icon: {
    fontSize: '20px',
  },
  textCenter: {
    textAlign: 'center',
  },
};

export default styles;
