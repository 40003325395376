import paymentType from '../constants/paymentType';

const getPrice = (item) => {
  switch (item.paymentType) {
    case paymentType.oneTimePayment.id: {
      return item.price;
    }
    case paymentType.paymentPlan.id: {
      return `${item.price}x${item.paymentCount}`;
    }
    case paymentType.subscription.id: {
      return `${item.price} every ${item.subscriptionBillingPeriod} month${
        item.subscriptionBillingPeriod > 1 ? 's' : ''
      }`;
    }
    default: {
      return '-';
    }
  }
};

export default getPrice;
