import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

class ListField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: new Array(this.props.fieldsNumber).fill(''),
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const fullList = this.state.list.map((val, i) => this.props.value[i] || '');
    this.setState({ list: fullList });
  }

  onChange = (event) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const list = [...this.state.list];
    list[event.target.name] = event.target.value;
    this.setState({ list });
    this.props.handleData(list);
  };

  renderList = () => {
    return this.state.list.map((val, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid item xs={12} key={i}>
        <TextField
          name={`${i}`}
          placeholder="Bullet point"
          fullWidth
          value={val}
          onChange={this.onChange}
        />
      </Grid>
    ));
  };

  render() {
    return (
      <Grid container spacing={24}>
        {this.renderList()}
      </Grid>
    );
  }
}

export default ListField;
