import React, { memo, useRef } from 'react';
import { withStyles, Paper, Divider, IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import { useDrag, useDrop } from 'react-dnd';
import { trackItemType, sessionItemType } from './constants';
import globalStyles from './styles';

const styles = {
  item: globalStyles.itemWrapper,
  content: globalStyles.itemContent,
  paper: globalStyles.paper,
  divider: globalStyles.divider,
  iconButton: globalStyles.iconButton,
  draggable: globalStyles.draggable,
};

const TrackItem = ({
  item,
  trackId,
  handleMove,
  increaseNextTrackSessionId,
  handleDeleteTrackSession,
  classes,
}) => {
  const ref = useRef(null);

  const [{ draggingId }, drag] = useDrag(
    () => ({
      type: trackItemType,
      collect: (monitor) => ({
        draggingId: monitor.getItem()?.id,
        isTrackItem: monitor.getItemType() === trackItemType,
      }),
      item: { ...item, trackId },
    }),
    [item, trackId],
  );
  const [, drop] = useDrop(
    () => ({
      accept: [trackItemType, sessionItemType],
      hover(dragItem) {
        if (!ref?.current) {
          return;
        }
        if (dragItem.id === item.id) {
          return;
        }

        handleMove({ dragItem, targetItem: { ...item, trackId } });
        dragItem.order = item.order; // eslint-disable-line no-param-reassign
        dragItem.trackId = trackId; // eslint-disable-line no-param-reassign
      },
      drop(dragItem, monitor) {
        if (monitor.getItemType() === sessionItemType) {
          increaseNextTrackSessionId();
        }
      },
    }),
    [item, trackId, increaseNextTrackSessionId],
  );

  drop(drag(ref));

  const opacity = draggingId === item.id ? 0.4 : 1;

  return (
    <div ref={ref} className={classes.item} style={{ opacity }}>
      <Paper elevation={1} className={`${classes.paper} ${classes.draggable}`}>
        <span className={classes.content}>{item.name}</span>
        <Divider className={classes.divider} />
        <IconButton
          className={classes.iconButton}
          color="primary"
          onClick={() => handleDeleteTrackSession({ id: item.id, trackId })}
        >
          <TrashIcon />
        </IconButton>
      </Paper>
    </div>
  );
};

export default memo(withStyles(styles)(TrackItem));
