import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { LongTextInput, required, CREATE, SimpleForm } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { validatePassword } from '../../../utils/validators';
import dataProvider from '../../../providers/dataProvider';
import ToolbarCreatePassword from './ToolbarCreatePassword';
import { styles } from '../styles';

const style = (theme) => {
  return {
    ...styles,
    icon: {
      backgroundColor: theme.palette.secondary.main,
    },
    hint: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.grey[500],
    },
    form: {
      textAlign: 'center',
    },
    button: {
      display: 'block',
      margin: '20px auto 0px auto',
    },
    errors: {
      color: theme.palette.error.main,
    },
  };
};

const validateConfirmPassword = (value, allValues) => {
  if (allValues.password !== allValues.passwordConfirm) {
    return 'The password shoud be equal "password"';
  }
  return undefined;
};

const validatePasswordPattern = (value) => {
  if (validatePassword(value)) {
    return validatePassword(value);
  }
  return undefined;
};

class CreatePassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      status: false,
      error: [],
    };
  }

  componentDidMount() {
    this.setState({ code: this.getJsonFromUrl().code });
  }

  getJsonFromUrl = (url) => {
    // eslint-disable-next-line no-param-reassign
    if (!url) url = this.props.location.search;
    const query = url.substr(1);
    const result = {};
    // eslint-disable-next-line func-names
    query.split('&').forEach(function (part) {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  };

  save = (data) => {
    dataProvider(CREATE, 'account/complete_registration', {
      data: { ...data, registrationCode: this.state.code },
    })
      .then(() => {
        this.setState({ status: true });
      })
      .catch((err) => {
        if (typeof err === 'object') {
          const errors = err
            .map((item) => {
              return item.errors.map((val) => val);
            })
            .reduce((acc, val) => acc.concat(val), []);
          this.setState({ status: false, error: errors });
        }
      });
  };

  render() {
    const { classes, history } = this.props;
    const { status, error } = this.state;
    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className={classes.logo} src="./img/logo.svg" />
          </div>
          <br />
          {status ? (
            <>
              <Typography variant="title" component="h2" align="center">
                You’re All Set!
              </Typography>
              <Typography component="h5" align="center">
                You are set to get started as a GMB Admin! You can change your password anytime
                through your settings.
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => history.push('/login')}
              >
                Continue to GMB Admin
              </Button>
            </>
          ) : (
            <>
              <Typography component="h5" align="center">
                Welcome to the GMB Admin Panel! To sign in and get started, create <br /> a password
                to use with your email address.
              </Typography>
              <SimpleForm
                className={classes.form}
                save={this.save}
                toolbar={<ToolbarCreatePassword />}
              >
                <LongTextInput
                  source="password"
                  label="Create Your Password"
                  validate={[required(), validatePasswordPattern]}
                />
                <LongTextInput
                  source="passwordConfirm"
                  label="Confirm Your Password"
                  validate={[required(), validateConfirmPassword]}
                />
                <>
                  <div className={classes.errors}>
                    {error.map((err, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={i}>{err}</div>
                    ))}
                  </div>
                </>
              </SimpleForm>
            </>
          )}
        </Card>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(CreatePassword));
