import React, { memo, useCallback, useEffect, useState } from 'react';
import { SaveButton, Button } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import ListMUI from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import dataProvider from '../../providers/dataProvider';
import DialogTitleWithCloseIcon from '../../components/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalContainer: {
    width: 500,
  },
};

const AddProduct = ({ classes, advertisementProducts, changeAdvertisementProducts }) => {
  const [products, changeProducts] = useState([]);
  const [availableProducts, changeAvailableProducts] = useState([]);
  useEffect(() => {
    dataProvider('GET_ALL', 'admin/products').then((res) => {
      changeProducts(res.data.products);
    });
  }, []);

  const [checkedProducts, changeCheckedProducts] = useState([]);
  const handleToggleProduct = useCallback(
    (value) => () => {
      const currentIndex = checkedProducts.indexOf(value);
      const newChecked = [...checkedProducts];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      changeCheckedProducts(newChecked);
    },
    [checkedProducts],
  );

  const [isOpenModal, changeIsOpenModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    changeCheckedProducts([]);
    changeAvailableProducts(
      products.filter((a) => !advertisementProducts.find((b) => b.productId === a.id)),
    );
    changeIsOpenModal(true);
  }, [advertisementProducts, products]);

  const handleCloseModal = useCallback(() => {
    changeIsOpenModal(false);
  }, []);

  const handleSaveChanges = useCallback(() => {
    const temp = [];
    checkedProducts.forEach((i) => {
      temp.push({
        productId: i,
        name: products.find((a) => a.id === i).name,
        order: advertisementProducts.length + temp.length + 1,
      });
    });
    changeAdvertisementProducts((old) => [...old, ...temp]);

    changeIsOpenModal(false);
  }, [advertisementProducts.length, changeAdvertisementProducts, products, checkedProducts]);

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          disabled={advertisementProducts.length === products.length}
          onClick={handleOpenModal}
        >
          <div>Add new</div>
        </Button>
      </div>
      <Dialog
        maxWidth="md"
        style={{ textAlign: 'center' }}
        open={isOpenModal}
        onClose={handleCloseModal}
      >
        <DialogTitleWithCloseIcon onClose={handleCloseModal}>
          Add additional products
        </DialogTitleWithCloseIcon>
        <ListMUI>
          {availableProducts.map((item) => (
            <ListItem
              key={item.id}
              role={undefined}
              dense
              button
              onClick={handleToggleProduct(item.id)}
              className={classes.modalContainer}
            >
              <Checkbox
                checked={checkedProducts.indexOf(item.id) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </ListMUI>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleCloseModal}>
            <div>Cancel</div>
          </Button>
          <SaveButton onClick={handleSaveChanges} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(withStyles(styles)(AddProduct));
