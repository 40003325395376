import validator from 'validator';

export const validateEmail = (value) => {
  let errorMessage = '';
  if (!value) {
    errorMessage = 'Email should not be empty';
    return errorMessage;
  }
  // eslint-disable-next-line no-param-reassign
  value = value.trim();
  if (!validator.isEmail(value)) {
    errorMessage = 'Please enter a valid email address';
  }
  return errorMessage;
};

export const validatePassword = (value) => {
  let errorMessage = '';
  if (!value) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  value = value.trim();
  if (/[\sа-я]+/i.test(value)) {
    errorMessage = 'Use latin characters and special symbols except spaces';
  } else if (value.length < 8) {
    errorMessage = 'Password is too short, please use at least 8 characters';
  } else if (value.length > 30) {
    errorMessage = 'Password is too long, please use no more than 30 characters';
  }

  // eslint-disable-next-line consistent-return
  return errorMessage;
};

export const validateName = (values) => {
  let errorMessage = '';

  if (!/^[^\s]+(\s+[^\s]+)*$/i.test(values.firstName)) {
    errorMessage = "Field shouldn't contain spaces";
  }

  return errorMessage;
};

export const validateNameCustom = (values, name) => {
  let errorMessage = '';

  if (!/^(?!\s*$).+/g.test(values[name])) {
    errorMessage = "Field shouldn't contain only spaces";
  }
  if (values[name].length < 2) {
    errorMessage = 'Field should contains more than 2 letters';
  }

  return errorMessage;
};
