import React, { useState, useEffect } from 'react';
import { LongTextInput, SimpleForm, Button } from 'react-admin';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  Card,
  CardContent,
  Typography,
  Snackbar,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import dataProvider from '../../providers/dataProvider';

const AnnouncementBarManager = () => {
  const [message, setMessage] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [userCount, setUserCount] = useState(0);
  const [announcements, setAnnouncements] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const fetchUserCount = () => {
    dataProvider('GET_ONE', 'users/count?announcmentHidden=true')
      .then(({ data }) => {
        setUserCount(data.total);
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error fetching user count',
          severity: 'error',
        });
      });
  };

  const fetchActiveAnnouncement = () => {
    dataProvider('GET_ONE', 'admin/advertisement', { id: 'current' })
      .then(({ data }) => {
        setMessage(data.message);
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error loading announcement message',
          severity: 'error',
        });
      });
  };

  const fetchAnnouncements = () => {
    dataProvider('GET_LIST', 'admin/advertisement/current/all', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'createdAt', order: 'DESC' },
    })
      .then(({ data }) => {
        setAnnouncements(data);
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error loading announcements list',
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    fetchUserCount();
    fetchActiveAnnouncement();
    fetchAnnouncements();
  }, []);

  const handleActivate = (id) => {
    dataProvider('UPDATE', 'admin/advertisement/current/activate', {
      id,
      data: { is_active: true },
    })
      .then(() => {
        setNotification({
          open: true,
          message: 'Announcement activated',
          severity: 'success',
        });
        fetchAnnouncements(); // Refresh the list of announcements
        fetchActiveAnnouncement(); // Refresh the LongTextInput field
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error activating announcement',
          severity: 'error',
        });
      });
  };

  const handleSave = () => {
    dataProvider('CREATE_ANNOUNCEMENT', 'admin/advertisement', { id: 'current', data: { message } })
      .then(() => {
        setNotification({
          open: true,
          message: 'Announcement message updated',
          severity: 'success',
        });
        fetchAnnouncements(); // Refresh the list of announcements
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error updating announcement message',
          severity: 'error',
        });
      });
  };

  const handleResetAnnouncement = () => {
    dataProvider('CREATE', 'admin/advertisement/reset-announcement', {})
      .then(() => {
        setNotification({
          open: true,
          message: `${userCount} users' showAnnouncement flag has been reset to true`,
          severity: 'success',
        });
        setUserCount(0);
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error resetting announcement flag',
          severity: 'error',
        });
      });
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmReset = () => {
    handleResetAnnouncement();
    handleCloseConfirmDialog();
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const getNotificationStyle = (severity) => {
    switch (severity) {
      case 'error':
        return { backgroundColor: '#f44336', color: '#fff' };
      case 'warning':
        return { backgroundColor: '#ff9800', color: '#fff' };
      case 'success':
        return { backgroundColor: '#4caf50', color: '#fff' };
      default:
        return { backgroundColor: '#2196f3', color: '#fff' };
    }
  };

  const handleChange = (event) => {
    setMessage(event.target?.value);
  };

  const handleDelete = (id) => {
    dataProvider('DELETE', 'admin/advertisement/current', { id })
      .then(() => {
        setNotification({
          open: true,
          message: 'Announcement deleted',
          severity: 'success',
        });
        fetchActiveAnnouncement(); // Refresh the LongTextInput field
        fetchAnnouncements(); // Refresh the list of announcements
      })
      .catch(() => {
        setNotification({
          open: true,
          message: 'Error deleting announcement',
          severity: 'error',
        });
      });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="headline" gutterBottom>
          Announcement Bar Message
        </Typography>
        <SimpleForm save={handleSave} record={{ message }}>
          <LongTextInput
            source="message"
            label="Message"
            fullWidth
            rows={6}
            resettable
            value={message}
            onChange={handleChange}
          />
        </SimpleForm>
        <Button
          label={`Reset Announcement Flag for All Users (${userCount})`}
          onClick={handleOpenConfirmDialog}
          style={{ marginTop: '1rem' }}
          disabled={userCount === 0}
        />
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="headline" gutterBottom>
          Recent Announcements
        </Typography>
        <List>
          {announcements.map((announcement) => (
            <ListItem
              key={announcement.id}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <ListItemText
                primary={announcement.message}
                secondary={
                  <>
                    Active: {announcement.is_active ? 'Yes' : 'No'}
                    <br />
                    Last Updated:{' '}
                    {new Date(announcement.updated_at).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </>
                }
                style={{ maxWidth: '60%', overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
              <div>
                <Button
                  label="Activate"
                  disabled={announcement.is_active}
                  onClick={() => handleActivate(announcement.id)}
                />
                <Button
                  label="Delete"
                  onClick={() => handleDelete(announcement.id)}
                  style={{ marginLeft: '8px' }}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle id="confirm-dialog-title">Confirm Reset</DialogTitle>
        <DialogContent>
          Are you sure you want to reset the announcement flag for all users? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={handleCloseConfirmDialog} />
          <Button label="Confirm" onClick={handleConfirmReset} />
        </DialogActions>
      </Dialog>
      <Snackbar
        open={notification.open}
        autoHideDuration={1000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Card
          style={{
            ...getNotificationStyle(notification.severity),
            minWidth: '250px',
            maxWidth: '80%',
          }}
        >
          <CardContent style={{ padding: '8px 16px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" style={{ flexGrow: 1 }}>
              {notification.message}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleCloseNotification}>
              <CloseIcon />
            </IconButton>
          </CardContent>
        </Card>
      </Snackbar>
    </Card>
  );
};

export default AnnouncementBarManager;
