import React, { memo } from 'react';
import { DeleteButton } from 'react-admin';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';

const DeleteDialog = ({ record, isOpen, handleClose, basePath, resource, redirect }) => {
  return (
    <DeleteAlertDialog
      title="Delete Product"
      subtitle="Are you sure you want to delete product?"
      description={`If you delete product, all product information will be deleted,
        product_program and user_product relation also has been deleted,
        customers will no longer have access to it.`}
      record={record}
      open={isOpen}
      handleClose={handleClose}
    >
      <DeleteButton
        size="large"
        redirect={redirect || handleClose}
        basePath={basePath}
        record={record}
        resource={resource}
      />
    </DeleteAlertDialog>
  );
};

export default memo(DeleteDialog);
