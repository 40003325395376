import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { LongTextInput, CREATE, email, SimpleForm } from 'react-admin';
import { withRouter } from 'react-router-dom';
import dataProvider from '../../../providers/dataProvider';
import { styles } from '../styles';
import ToolbarForgotPassword from './ToolbarForgotPassword';

const style = {
  ...styles,
};

class ForgotPassword extends Component {
  state = {
    status: false,
  };

  save = (data) => {
    dataProvider(CREATE, 'account/reset_request', {
      data: { email: data.email },
    })
      .then(() => {
        this.setState({ status: true });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { classes } = this.props;
    const { status } = this.state;
    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img className={classes.logo} src="./img/logo.svg" />
          </div>
          <br />
          {status ? (
            <>
              <Typography variant="headline" align="center">
                WE JUST EMAILED YOU A LINK
              </Typography>
              <Typography component="h5" align="center">
                Please check your email and follow the directions <br /> to set up a new password
                for your account.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="headline" align="center">
                FORGOT PASSWORD?
              </Typography>
              <Typography component="h5" align="center">
                Enter the email address associated with your account, <br /> and we’ll send you a
                link to reset your password.
              </Typography>
              <SimpleForm
                className={classes.form}
                save={this.save}
                toolbar={<ToolbarForgotPassword />}
              >
                <LongTextInput
                  type="email"
                  source="email"
                  label="Write actual email"
                  validate={email()}
                />
              </SimpleForm>
            </>
          )}
        </Card>
      </div>
    );
  }
}

export default withStyles(style)(withRouter(ForgotPassword));
