import React, { memo } from 'react';
import { Show, TabbedShowLayout, Tab, TextField, FunctionField } from 'react-admin';
import { paymentTypeById } from '../../constants/paymentType';
import getPrice from '../../utils/getPrice';
import ImgField from '../../components/ImgField/ImgField';

const ShowProduct = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Main Info">
          <ImgField source="picture" title="Picture" addLabel width="200" height="200" />
          <TextField source="name" />
          <TextField source="link" />
          <TextField source="externalProductId" />
          <TextField source="description" />
        </Tab>
        <Tab label="Prices">
          <FunctionField
            label="Payment Type"
            render={(record) => paymentTypeById[record.paymentType]}
          />
          <FunctionField label="Price, $" render={(record) => getPrice(record)} />
          <TextField source="stripePriceId" label="Stripe Price Id" />
          <TextField source="upsellPrice" label="Upsell price, $" />
          <TextField source="upsellStripePriceId" />
        </Tab>
        <Tab label="Programs">
          <FunctionField
            source="programs"
            render={(data) => {
              return data.programs.map((i) => (
                <div style={{ marginTop: 10 }} key={i.id}>
                  {`${i.name} ${
                    i.limitedAccess
                      ? `(${i.limitedAccess} month${i.limitedAccess > 1 ? 's' : ''})`
                      : ''
                  }`}
                </div>
              ));
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default memo(ShowProduct);
