import React from 'react';
import { Layout } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AppMenu from '../AppMenu/AppMenu';
import AppBarCustom from '../AppBar/AppBar';
import AppSideBar from '../AppSideBar/AppSideBar';

const styles = () => ({
  appFrame: {
    padding: '64px 0',
  },
  contentWithSidebar: {
    display: 'block',
    padding: 0,
  },
  content: {
    marginTop: '50px',
    padding: '20px 24px',
  },
});

const AppLayout = (props) => {
  return <Layout {...props} sidebar={AppSideBar} appBar={AppBarCustom} menu={AppMenu} />;
};
export default withStyles(styles)(AppLayout);
