import React, { Component } from 'react';
import { createBrowserHistory as createHistory } from 'history';
import englishMessages from 'ra-language-english';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './App.css';
import { Admin, Resource } from 'react-admin';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import authProvider from './providers/authProvider';
import AppLayout from './components/AppLayout/AppLayout';
import AppRouters from './AppRoutes';
import Dashboard from './modules/Dashboard/Dashboard';
import ExercisesList from './modules/Exercises/ExercisesList/ExercisesList';
import ExercisesCreate from './modules/Exercises/ExercisesCreate/ExercisesCreate';
import dataProvider from './providers/dataProvider';
import ExercisesEdit from './modules/Exercises/ExercisesEdit/ExercisesEdit';
import ListPrograms from './modules/Programs/ListPrograms/ListPrograms';
import CreateProgram from './modules/Programs/CreateProgram/CreateProgram';
import EditProgram from './modules/Programs/EditProgram/EditProgram';
import SessionDetail from './modules/Sessions/SessionDetail/SessionDetail';
import ListAdmins from './modules/Admins/ListAdmins/ListAdmins';
import ListCustomers from './modules/Customers/ListCustomers/ListCustomers';
import CreateCustomer from './modules/Customers/CreateCustomer/CreateCustomer';
import EditCustomer from './modules/Customers/EditCustomer/EditCustomer';
import ShowCustomer from './modules/Customers/ShowCustomer/ShowCustomer';
import ShowProgram from './modules/Programs/ShowProgram/ShowProgram';
import ExercisesShow from './modules/Exercises/ExercisesShow/ExercisesShow';
import CreateAdmin from './modules/Admins/CreateAdmin/CreateAdmin';
import EditAdmin from './modules/Admins/EditAdmin/EditAdmin';
import { ListMedia, EditMedia } from './modules/Media';
import { ListProducts, EditProduct, CreateProduct, ShowProduct } from './modules/Products';
import AdvertisementList from './modules/Advertisement';
import Questions from './modules/Questions';
import roles from './constants/roles';
import isStaff from './utils/isStaff';
import { AchievementManager } from './modules/Achievements';

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
    appName: 'Praxis Admin',
    apiDomain: process.env.REACT_APP_API_URL,
    websiteDomain: process.env.REACT_APP_ADMIN_URL,
    apiBasePath: '/api/auth',
    websiteBasePath: '/auth',
  },
  recipeList: [
    EmailPassword.init(),
    Session.init({
      cookieDomain: '.app.gmb.io',
    }),
  ],
});

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  beforeSend(event) {
    // do not send any errors except video uploading errors
    if (event.message?.match(/video uploading/i)) {
      return event;
    }
    return false;
  },
  tracesSampleRate: 1.0,
});
Sentry.setTag('part', 'admin');

const history = createHistory();

const i18nProvider = () => {
  // Always fallback on english
  const messages = { ...englishMessages };
  messages.ra.custom = {
    notification: {
      sync_success: 'Posse Access Synchronize Successful',
      sync_failed: 'Posse Access Synchronize Failed',
    },
  };
  return messages;
};

class App extends Component {
  render() {
    return (
      <SuperTokensWrapper>
        <Admin
          history={history}
          customRoutes={AppRouters}
          appLayout={AppLayout}
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard}
          customSagas={[]}
          i18nProvider={i18nProvider}
          loginPage={null}
        >
          {(permissions) => {
            let user = localStorage.getItem('user');
            user = user ? JSON.parse(user) : {};
            if (!isStaff(user)) {
              return [
                <Resource
                  name="users"
                  edit={
                    permissions === roles.superAdmin || permissions === roles.admin
                      ? EditCustomer
                      : null
                  }
                  show={ShowCustomer}
                />,
              ];
            }
            return [
              permissions === roles.superAdmin ? (
                <Resource
                  name="admins"
                  create={CreateAdmin}
                  edit={EditAdmin}
                  options={{ label: 'admins' }}
                  list={ListAdmins}
                />
              ) : null,
              <Resource
                name="users"
                create={
                  permissions === roles.superAdmin || permissions === roles.admin
                    ? CreateCustomer
                    : null
                }
                edit={
                  permissions === roles.superAdmin || permissions === roles.admin
                    ? EditCustomer
                    : null
                }
                show={ShowCustomer}
                options={{ label: 'customers' }}
                list={ListCustomers}
              />,
              <Resource
                name="programs"
                show={ShowProgram}
                list={ListPrograms}
                edit={permissions === roles.superAdmin ? EditProgram : null}
                create={permissions === roles.superAdmin ? CreateProgram : null}
                options={{ label: 'programs' }}
              />,
              <Resource
                name="admin/products"
                list={ListProducts}
                show={ShowProduct}
                edit={permissions === roles.superAdmin && EditProduct}
                create={permissions === roles.superAdmin && CreateProduct}
                options={{ label: 'products' }}
              />,
              <Resource
                name="exercise"
                options={{ label: 'exercises' }}
                show={ExercisesShow}
                list={ExercisesList}
                create={permissions === roles.superAdmin ? ExercisesCreate : null}
                edit={permissions === roles.superAdmin ? ExercisesEdit : null}
              />,
              <Resource
                name="sessions"
                edit={SessionDetail}
                list={null}
                show={null}
                options={{ label: '' }}
              />,
              <Resource
                name="admin/advertisement"
                list={AdvertisementList}
                options={{ label: 'advertisements' }}
              />,
              <Resource name="admin/questions" list={Questions} options={{ label: 'questions' }} />,
              <Resource
                name="achievements"
                list={AchievementManager}
                edit={null}
                show={null}
                options={{ label: 'achievements' }}
              />,
              <Resource
                name="file/video"
                list={ListMedia}
                edit={EditMedia}
                show={null}
                options={{ label: 'videos' }}
              />,
            ];
          }}
        </Admin>
      </SuperTokensWrapper>
    );
  }
}

export default App;
