export const AchievementType = {
  1: 'Session',
  2: 'Week',
  3: 'Program',
  4: 'Forum',
  5: 'Profile',
  6: 'WorkoutRating',
  7: 'PrepSession',
};

export const TriggerType = {
  1: 'Coded',
  2: 'Times',
};
