import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  TextField,
  FunctionField,
  SimpleShowLayout,
  DeleteButton,
  BooleanField,
  DateField,
  CREATE,
  showNotification,
  refreshView,
} from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import ImgField from '../../../components/ImgField/ImgField';
import ListProgramsModal from './ListProgramsModal';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import ResetPasswordModal from './ResetPasswordModal';
import dataProvider from '../../../providers/dataProvider';
import roles from '../../../constants/roles';

const styles = {
  showLayout: {
    paddingTop: '0px !important',
    paddingBottom: '0px',
  },
};

class ShowGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      modalOpened: false,
      isOpen: false,
      record: null,
      openResetPassword: false,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleModalOpen = () => {
    this.setState({ modalOpened: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpened: false });
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  handleModalOpenResetPassword = () => {
    this.setState({ openResetPassword: true });
    this.handleMenuClose();
  };

  handleModalCloseResetPassword = () => {
    this.setState({ openResetPassword: false });
  };

  handleResetPassword = () => {
    dataProvider(CREATE, 'account/reset_request', {
      data: { email: this.props.record.email },
    }).then(() => {
      this.handleModalCloseResetPassword();
    });
  };

  handleSynchronizePosseAccess = () => {
    const { record: { id: userId } = {} } = this.props;

    dataProvider(CREATE, `users/${userId}/posse-access-sync`, { data: {} })
      .then(() => {
        showNotification('ra.custom.notification.sync_success', 'info');
        refreshView();
      })
      .catch(() => {
        showNotification('ra.custom.notification.sync_failed', 'error');
      });
  };

  render() {
    const { classes, history, permissions, ...props } = this.props;
    const { anchorEl } = this.state;
    return (
      <>
        <Grid container spacing={8}>
          <Grid item md={3} align="center">
            <ImgField
              {...props}
              source="picture"
              title="Picture"
              addLabel
              width="270"
              height="270"
            />
          </Grid>
          <Grid item md={8}>
            <SimpleShowLayout {...props} className={classes.showLayout}>
              <FunctionField
                source="Name"
                render={(record) => `${record.firstName} ${record.lastName}`}
              />
              <TextField source="email" />
              <DateField source="createdAt" label="Date Joined" />
              <BooleanField source="questionnaireComplete" label="Intro Questionairre Complete" />
              <BooleanField source="apAccess" label="AP access" />
              <TextField source="apUsername" label="apUsername" />
              <TextField source="apId" label="apId" />
              <TextField source="apTopicId" label="AP Topic Id" />
            </SimpleShowLayout>
          </Grid>
          <Grid item md={1}>
            {permissions === roles.superAdmin || permissions === roles.admin ? (
              <Button
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                variant="outlined"
                onClick={this.handleMenuOpen}
              >
                Options
              </Button>
            ) : null}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={() => history.push(`/users/${this.props.record.id}`)}>
                Edit Customer
              </MenuItem>
              <MenuItem onClick={this.handleModalOpen}>Edit Program Access</MenuItem>
              <MenuItem onClick={this.handleSynchronizePosseAccess}>
                Synchronize Posse Access
              </MenuItem>
              <MenuItem onClick={this.handleModalOpenResetPassword}>Reset Password</MenuItem>
              <MenuItem onClick={() => this.setState({ isOpen: true, record: this.props.record })}>
                Delete Customer
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <ListProgramsModal
          userId={this.props.record.id}
          opened={this.state.modalOpened}
          close={this.handleModalClose}
        />
        <DeleteAlertDialog
          title="Delete customer"
          subtitle="Are you sure you want to delete customer ?"
          description="Customer will be deleted permanently."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            size="large"
            redirect="list"
            basePath={this.props.basePath}
            record={this.state.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
        <ResetPasswordModal
          open={this.state.openResetPassword}
          handleResetPassword={this.handleResetPassword}
          handleClose={this.handleModalCloseResetPassword}
        />
      </>
    );
  }
}

ShowGrid.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  permissions: PropTypes.string.isRequired,
};

export default withStyles(styles)(connect(null, { showNotification, refreshView })(ShowGrid));
