import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { crudUpdate } from 'react-admin';
import UserPicture from '../../../components/UserPicture/UserPicture';
import EditAvatarModal from './EditAvatarModal';
import uploadService from '../../../utils/upload';

class GridProfile extends PureComponent {
  handleAvatar = (img) => {
    const { record } = this.props;
    if (typeof img.picture === 'object') {
      const formData = new FormData();
      formData.append('file', img.picture.rawFile);
      uploadService(formData).then((res) => {
        this.props.updateUser(
          {
            id: record.id,
            picture: res.data.fileName,
          },
          `/profile`,
        );
      });
    }
  };

  render() {
    const { record } = this.props;
    return (
      <>
        <Grid container spacing={8}>
          <Grid item md={12}>
            <h2>My Profile</h2>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item md={3}>
            <EditAvatarModal onSave={this.handleAvatar}>
              <UserPicture />
            </EditAvatarModal>
          </Grid>
          <Grid item md={9}>
            <Grid container spacing={8}>
              <Grid item md={9}>
                <Typography variant="title">{`${record.firstName} ${record.lastName}`}</Typography>
              </Grid>
              <Grid item md={9}>
                <Typography>Email: {record.email}</Typography>
              </Grid>
              <Grid item md={9}>
                <Typography>Password: ***********</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const updateUser = (values, redirectTo) => {
  return crudUpdate('users', values.id, values, values, `/users`, redirectTo);
};

export default connect(undefined, { updateUser })(GridProfile);
