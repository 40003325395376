const equipment = [
  { id: 0, name: '-' },
  { id: 1, name: 'BodyWeight' },
  { id: 2, name: 'Rings' },
  { id: 3, name: 'Parallettes' },
];

export const equipmentSelectableOptions = equipment.filter((i) => i.id !== 0);

export default equipment;
