export function sumOfCol(item, cell) {
  let cellN = 0;
  if (item.exercise && item.exercise.type === 1) {
    cellN = parseInt(item.timeMatrix[cell], 10) * 3;
  } else {
    cellN = parseInt(item.timeMatrix[cell], 10);
  }
  if (item.exercise && item.exercise.forEachSide) {
    cellN += cellN;
  }
  if (Number.isNaN(cellN)) {
    cellN = 0;
  }
  return cellN;
}

export function convertToMinuts(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return { min: minutes, sec: seconds };
}
