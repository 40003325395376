/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react';
import {
  Create,
  SimpleForm,
  ImageInput,
  TextInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  LongTextInput,
  ImageField,
  crudCreate,
  Toolbar,
  SaveButton,
  required,
} from 'react-admin';
import { connect } from 'react-redux';
import { equipmentSelectableOptions } from '../../../constants/equipment';
import { difficultySelectableOptions } from '../../../constants/difficulty';
import uploadService from '../../../utils/upload';
import Loading from '../../../components/Loading/Loading';

const saveProgram = (values, basePath) => {
  return crudCreate('programs', values, basePath, '/programs');
};

class ProgramCreateToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  uploadImg = (img) => {
    const formData = new FormData();
    formData.append('file', img.rawFile);
    return uploadService(formData);
  };

  handleClick = () => {
    const { basePath, handleSubmit, saveProgram: saveProgramInner } = this.props;
    return handleSubmit((values) => {
      this.setState({ loading: true });
      this.uploadImg(values.picture)
        .then((res) => {
          const newValues = { ...values };
          if (values.isExternal) {
            newValues.complexity = 0;
            newValues.equipment = 0;
          } else {
            delete newValues.externalLink;
            delete newValues.isAlphaPosse;
          }
          saveProgramInner({ ...newValues, picture: res.data.fileName }, basePath);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { handleSubmitWithRedirect, saveProgram: saveProgramInner, ...props } = this.props;
    return (
      <>
        <Loading open={this.state.loading} />

        <Toolbar {...props}>
          <SaveButton redirect="list" handleSubmitWithRedirect={this.handleClick} {...props} />
        </Toolbar>
      </>
    );
  }
}

const ProgramCreateToolbarConnect = connect(undefined, { saveProgram })(ProgramCreateToolbar);

class CreateProgram extends PureComponent {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm toolbar={<ProgramCreateToolbarConnect />} submitOnEnter={false}>
          <TextInput source="name" label="Program Name" validate={required()} />
          <BooleanInput source="isExternal" label="Is External" />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.isExternal) {
                return (
                  <>
                    <BooleanInput
                      source="isAlphaPosse"
                      label="Is Alpha Posse, (allow add/remove access to discourse for this program)"
                      {...rest}
                    />
                    <br />
                    <TextInput
                      source="externalLink"
                      label="External Link"
                      validate={required()}
                      {...rest}
                    />
                  </>
                );
              }

              return (
                <>
                  <SelectInput
                    label="Equipment"
                    source="equipment"
                    choices={equipmentSelectableOptions}
                    validate={required()}
                    {...rest}
                  />
                  <br />
                  <SelectInput
                    label="Type"
                    source="complexity"
                    choices={difficultySelectableOptions}
                    validate={required()}
                    {...rest}
                  />
                </>
              );
            }}
          </FormDataConsumer>
          <LongTextInput source="description" label="Description" validate={required()} />
          <ImageInput
            source="picture"
            label="Add Program Photo"
            accept="image/*"
            validate={required()}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </Create>
    );
  }
}

export default CreateProgram;
