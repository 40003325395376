import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatInput = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      required
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue.trim(),
          },
        });
      }}
      format="##:##:##.###"
    />
  );
};

export default NumberFormatInput;
