import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { ImageInput } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';
import { uniqBy } from 'lodash';
import Typography from '@material-ui/core/Typography';

const styles = {
  button: {
    color: '#f44336',
    cursor: 'pointer',
  },
};

class CueImagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.values,
    };
  }

  onBlur = (event) => {
    const images = Object.values(event)
      .filter((item) => item.rawFile)
      .map((item) => ({ picture: item, description: '' }));
    if (this.state.images.length + images.length > 6) {
      return;
    }

    const newImg = this.state.images.filter((item) => typeof item.picture !== 'string');
    const oldImg = this.state.images.filter((item) => typeof item.picture === 'string');
    const newList = uniqBy([...images, ...newImg], (item) => {
      return item.picture.src;
    });
    this.setState({ images: [...newList, ...oldImg] });
    this.props.handleData([...newList, ...oldImg]);
  };

  onChange = (event) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const cacheImgs = [...this.state.images];
    cacheImgs[event.target.name] = {
      ...cacheImgs[event.target.name],
      description: event.target.value,
    };
    this.setState({ images: cacheImgs });
    this.props.handleData(cacheImgs);
  };

  getSrc = (picture) => {
    if (picture && picture.src) {
      return picture.src;
    }
    return `${process.env.REACT_APP_IMAGES_FILE_HOST}/fit-in/50x50/${picture}`;
  };

  handleDelete = (i) => {
    const imgs = [...this.state.images];
    const imgsFiltered = imgs.filter((img, index) => index !== i);
    this.setState({ images: imgsFiltered });
    this.props.handleData(imgsFiltered);
  };

  renderErrorOfLimit = (images) => {
    if (images.length >= 6) {
      return (
        <Typography gutterBottom color="error" component="h5">
          You have reached the form cue image limit. To add a new image, delete one of the images
          below.
        </Typography>
      );
    }
    return null;
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="title" component="h4">
          Form Cue Images
        </Typography>
        <ImageInput
          options={{
            disabled: this.state.images.length >= 6,
          }}
          source="imagesForCue"
          label="Form Cue Images"
          onBlur={this.onBlur}
          multiple
          accept="image/*"
        >
          <div source="src" />
        </ImageInput>
        {this.renderErrorOfLimit(this.state.images)}
        <br />
        {this.state.images.map((img, i) => {
          return (
            <Grid container justify="center" alignItems="center" spacing={16} key={img.picture}>
              <Grid item xs={1}>
                <img width="50px" height="50px" src={this.getSrc(img.picture)} alt="cue" />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  required
                  name={`${i}`}
                  placeholder="Text"
                  fullWidth
                  multiple
                  value={img.description}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={1}>
                <DeleteIcon onClick={() => this.handleDelete(i)} className={classes.button} />
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }
}

export default withStyles(styles)(CueImagesList);
