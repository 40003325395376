import React, { memo } from 'react';
import { List, Datagrid, TextField, NumberField, FunctionField, ImageField } from 'react-admin';
import { AchievementType, TriggerType } from './achievementConstants';
import AchievementEdit from './AchievementEdit';

const AchievementList = (props) => {
  return (
    <List {...props} perPage={100} exporter={false} bulkActionButtons={false}>
      <Datagrid expand={<AchievementEdit />}>
        <TextField source="name" />
        <TextField source="description" />
        <NumberField source="programId" />
        <FunctionField source="type" render={(record) => AchievementType[record.type]} />
        <FunctionField source="triggerType" render={(record) => TriggerType[record.triggerType]} />
        <NumberField source="timesValue" />
        <ImageField source="icon" title="name" />
      </Datagrid>
    </List>
  );
};

export default memo(AchievementList);
