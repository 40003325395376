import React, { PureComponent } from 'react';
import {
  ImageInput,
  LongTextInput,
  CheckboxGroupInput,
  BooleanInput,
  required,
  translate,
  CREATE,
  UPDATE,
  showNotification,
  crudCreate,
  crudUpdate,
  crudGetOne,
} from 'react-admin';
import compose from 'recompose/compose';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import uploadService from '../../../utils/upload';
import ImgField from '../../../components/ImgField/ImgField';

class SessionModal extends PureComponent {
  uploadImg = (img) => {
    if (typeof img === 'object') {
      const formData = new FormData();
      formData.append('file', img.rawFile);
      return uploadService(formData);
    }
    return Promise.resolve({ data: { fileName: img } });
  };

  createDurationOptions = (durationOptions) => {
    if (!Array.isArray(durationOptions)) {
      return durationOptions;
    }
    const value = {};
    durationOptions.forEach((el) => {
      value[el] = true;
    });
    return value;
  };

  create = (event) => {
    Promise.all([this.uploadImg(event.picture)])
      .then((res) => {
        const data = {
          ...event,
          picture: res[0].data.fileName,
          programId: this.props.programId,
          durationOptions: this.createDurationOptions(event.durationOptions),
          isBasic: event.isBasic === undefined ? false : event.isBasic,
        };
        // return this.props.createSession(data, `/programs/${this.props.programId}`);
        return dataProvider(CREATE, 'sessions', { data });
        // return this.props.createSession(data, ``);
      })
      .then(() => {
        this.props.reset();
        this.props.handleClose();
        this.props.refreshList();
        this.props.getOnePrograms(this.props.programId);
      })
      .catch((err) => {
        this.props.showNotification(`Error ${err}`, 'error');
      });
  };

  update = (event) => {
    Promise.all([this.uploadImg(event.picture)])
      .then((res) => {
        const data = {
          ...event,
          picture: res[0].data.fileName,
          programId: this.props.programId,
          durationOptions: this.createDurationOptions(event.durationOptions),
        };
        // this.props.updateSession(data, `/programs/${this.props.programId}`);
        // this.props.updateSession(data, ``);
        return dataProvider(UPDATE, 'sessions', { id: data.id, data });
      })
      .then(() => {
        this.props.reset();
        this.props.handleClose();
        this.props.refreshList();
        // this.props.getOnePrograms(this.props.programId);
      })
      .catch((error) => {
        this.props.showNotification(`Error ${error.response}`, 'error');
      });
  };

  handleSubmit = (event) => {
    if (event.id) {
      this.update(event);
    } else {
      this.create(event);
    }
  };

  handleClose = () => {
    this.props.reset();
    this.props.handleClose();
  };

  render() {
    const { handleSubmit, initialValues } = this.props;
    return (
      <>
        <Dialog
          maxWidth="md"
          open={this.props.isOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Session</DialogTitle>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <DialogContent>
              <ImageInput
                source="picture"
                label="Session Photo"
                accept="image/*"
                format={(v) => (typeof v === 'string' ? { picture: v } : v)}
                validate={required((_) => 'ra.validation.required')}
              >
                <ImgField width="100" height="100" source="picture" />
              </ImageInput>
              <LongTextInput source="name" label="Session name" validate={required()} />
              <div>Duration Options (in minutes)</div>
              <CheckboxGroupInput
                source="durationOptions"
                validate={required()}
                disabled={!!(initialValues && initialValues.id)}
                format={(v) => {
                  if (v && !Array.isArray(v)) {
                    return Object.keys(v);
                  }
                  return v;
                }}
                label="These are approximate session durations. Final duration will vary depending on the exercises added on each session"
                choices={[
                  { id: '5', name: '5' },
                  { id: '10', name: '10' },
                  { id: '15', name: '15' },
                  { id: '20', name: '20' },
                  { id: '30', name: '30' },
                  { id: '35', name: '35' },
                  { id: '45', name: '45' },
                  { id: '60', name: '60' },
                  { id: '75', name: '75' },
                ]}
              />
              <LongTextInput source="description" label="Description" validate={required()} />
              <BooleanInput label="Basic Level" source="isBasic" />
              <Typography component="div">
                Mark this session as basic level if you want this to be the session customers get
                when they indicate they are feeling more tire/sore/stiff than usual at the end of
                the prep phase.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="default">
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

const createSession = (values, redirectTo) => {
  return crudCreate('sessions', values, '/sessions', redirectTo);
};
const updateSession = (values, redirectTo) => {
  return crudUpdate('sessions', values.id, values, values, '/sessions', redirectTo);
};
const getOnePrograms = (id) => {
  return crudGetOne('programs', id, '/programs', true);
};
const enhance = compose(
  translate,
  reduxForm({
    form: 'NewSession',
    initialValues: {},
    validate: (values, props) => {
      const errors = {};
      if (!props.initialValues && values.order && props.listDays.includes(values.order)) {
        errors.order = 'This Day is already created';
      }
      return errors;
    },
  }),
  connect(undefined, {
    createSession,
    updateSession,
    getOnePrograms,
    showNotification,
  }),
);

export default enhance(SessionModal);
