import React from 'react';
import { TextInput, Filter } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  input: {
    width: 300,
  },
};

const FilterCustomer = withStyles(styles)(({ classes, ...props }) => {
  return (
    <>
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn className={classes.input} resettable />
      </Filter>
    </>
  );
});

export default FilterCustomer;
