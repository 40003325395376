import React, { memo } from 'react';
import { withStyles, Button, TextField, Paper } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import TrackItem from './TrackItem';
import globalStyles from './styles';
import { trackItemType, sessionItemType, defaultTrackType } from './constants';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 50,
    alignItems: 'center',
  },
  title: {
    ...globalStyles.itemWrapper,
    marginTop: 10,
    backgroundColor: 'lightGrey',
    padding: '0 20px',
    fontSize: 16,
    height: 70,
  },
  list: {
    ...globalStyles.list,
    marginTop: 10,
  },
  additionalSpace: {
    flexGrow: 2,
    minHeight: 100,
    background: '#f5f5f5',
    border: '1px dashed #ccc',
  },
};

const TrackColumn = ({
  track,
  handleMove,
  handleDrop,
  increaseNextTrackSessionId,
  handleRename,
  handleDeleteTrackSession,
  handleDeleteTrack,
  handleCopyTrack,
  classes,
}) => {
  const [, dropRef] = useDrop(() => ({
    accept: [trackItemType, sessionItemType],
    drop(dragItem) {
      handleDrop({ dragItem, targetTrackId: track.id });
      if (dragItem.trackId === null || dragItem.isNew) {
        increaseNextTrackSessionId();
      }
    },
  }));

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Button
          size="small"
          color="primary"
          onClick={() => handleDeleteTrack({ trackId: track.id })}
          disabled={track.type === defaultTrackType}
        >
          delete
        </Button>
        <Button size="small" color="primary" onClick={() => handleCopyTrack({ trackId: track.id })}>
          copy
        </Button>
      </div>
      <Paper elevation={1} className={classes.title}>
        <TextField
          placeholder="Track name"
          fullWidth
          value={track.name}
          onChange={({ target: { value } }) => handleRename({ value, trackId: track.id })}
          helperText={track.answers?.join(', ') || 'No answers'}
        />
      </Paper>
      <div className={classes.list}>
        {track.sessions.map((item) => (
          <TrackItem
            key={item.id}
            item={item}
            trackId={track.id}
            handleMove={handleMove}
            increaseNextTrackSessionId={increaseNextTrackSessionId}
            handleDeleteTrackSession={handleDeleteTrackSession}
          />
        ))}
      </div>
      <div className={classes.additionalSpace} ref={dropRef} />
    </div>
  );
};

export default memo(withStyles(styles)(TrackColumn));
