import React, { PureComponent } from 'react';
import {
  SimpleForm,
  SimpleShowLayout,
  ImageInput,
  LongTextInput,
  CheckboxGroupInput,
  BooleanInput,
  FileInput,
  FormDataConsumer,
  required,
  crudGetOne,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import ImgField from '../../../components/ImgField/ImgField';
import SessionExercises from '../SessionExercises/SessionExercises';
import uploadVideoService from '../../../utils/uploadVideo';
import uploadService from '../../../utils/upload';
import FileFieldCustom from '../../../components/FileFieldCustom/FileFieldCustom';
import SessionDetailToolbar from './SessionDetailToolbar';
import durationOptions from '../../../constants/durationOptions';
import Loading from '../../../components/Loading/Loading';

const styles = {
  simpleShowLayout: {
    width: 'inherit',
    marginBottom: '20px',
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  },
  simpleShowLayoutTitle: {
    paddingLeft: '0',
  },
  linkBack: {
    margin: '10px 0 0px 25px',
    display: 'block',
  },
};

class SessionDetailForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  uploadImg = (img) => {
    if (typeof img === 'object') {
      const formData = new FormData();
      formData.append('file', img.rawFile);
      return uploadService(formData);
    }
    return Promise.resolve({ data: { url: img } });
  };

  uploadVideoIntroScreen = (introScreen) => {
    const video = introScreen && introScreen.video;
    if (!video) {
      return Promise.resolve(null);
    }
    if (video && video.rawFile) {
      return uploadVideoService(video);
    }
    if (video && video.id) {
      return Promise.resolve({ data: { id: video.id } });
    }
    return Promise.resolve(null);
  };

  // eslint-disable-next-line no-shadow
  createDurationOptions = (durationOptions) => {
    if (!Array.isArray(durationOptions)) {
      return durationOptions;
    }
    const value = {};
    durationOptions.forEach((el) => {
      value[`${el}`] = true;
    });
    return value;
  };

  isIntroScreen = (introScreen) => {
    if (!introScreen) {
      return false;
    }
    return !!Object.values(introScreen).filter((item) => item).length;
  };

  save = (values) => {
    const data = { ...values };
    this.setState(() => ({ loading: true }));
    if (!this.isIntroScreen(data.introScreen)) {
      data.introScreen = null;
    }
    Promise.all([this.uploadImg(data.picture), this.uploadVideoIntroScreen(data.introScreen)]).then(
      (res) => {
        data.picture = res[0].data.fileName;
        data.durationOptions = this.createDurationOptions(data.durationOptions);
        if (res[1]) {
          data.introScreen.videoId = res[1].id;
        } else if (data.introScreen && !data.introScreen.video) {
          data.introScreen.videoId = null;
        }
        this.setState(() => ({ loading: false }));
        const { location } = this.props.history;
        // @todo fix for reload page without lose data
        this.props.save(
          data,
          location.state && location.state.previous ? location.state.previous : false,
        );
      },
    );
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, getOneSession, ...props } = this.props;
    return (
      <>
        {this.props.record.program ? (
          <Link to={`/programs/${this.props.record.program.id}`} className={classes.linkBack}>
            Back to program
          </Link>
        ) : null}
        <Loading open={this.state.loading} />
        <SimpleForm {...props} redirect="edit" save={this.save} toolbar={<SessionDetailToolbar />}>
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <ImageInput
              source="picture"
              label="Session Photo"
              accept="image/*"
              format={(v) => (typeof v === 'string' ? { picture: v } : v)}
              validate={required((_) => 'ra.validation.required')}
            >
              <ImgField width="100" height="100" source="picture" />
            </ImageInput>
            <LongTextInput source="name" label="Session name" validate={required()} />
            <div>Duration Options (in minutes)</div>
            <CheckboxGroupInput
              source="durationOptions"
              disabled
              validate={required()}
              format={(v) => {
                if (v && !Array.isArray(v)) {
                  return Object.keys(v);
                }
                return v;
              }}
              label="These are approximate session durations. Final duration will vary depending on the exercises added on each session"
              choices={durationOptions}
            />
            <LongTextInput source="description" label="Description" validate={required()} />
            <BooleanInput label="Basic Level" source="isBasic" />
          </SimpleShowLayout>
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <h4>Introduction Screen</h4>
            <LongTextInput source="introScreen.title" label="Title" />
            <FileInput source="introScreen.video" label="Intro video" accept="video/mp4">
              <FileFieldCustom
                isSubtitle
                source="introVideo"
                values={this.props.record}
                checkedName="introScreen.video"
                checkedResource="sessions"
              />
            </FileInput>
            <LongTextInput source="introScreen.text" label="Text" />
          </SimpleShowLayout>
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <h4>Session Detail Bullet Points</h4>
            <LongTextInput source="bullets.0" label="Text" />
            <LongTextInput source="bullets.1" label="Text" />
            <LongTextInput source="bullets.2" label="Text" />
          </SimpleShowLayout>
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <FormDataConsumer>
              {({ formData }) => {
                return <SessionExercises source="sessionExercise" values={formData} />;
              }}
            </FormDataConsumer>
          </SimpleShowLayout>
        </SimpleForm>
      </>
    );
  }
}

const getOneSession = (id) => {
  return crudGetOne('sessions', id, '/sessions', true);
};

export default connect(undefined, { getOneSession })(withStyles(styles)(SessionDetailForm));
