import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EmptyBlock from './EmptyBlock';
import dataProvider from '../../../providers/dataProvider';

class OnbordingQuestions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    dataProvider('GET_ALL', `questionnaire/user/${userId}/answers`, {}).then((res) =>
      this.setState(() => ({ list: res.data })),
    );
  }

  getAsnswer = (data) => {
    if (!data) {
      return;
    }
    const answers = data.map((item) => {
      if (item.title === 'Other') {
        return item.textValue;
      }
      if (item.items) {
        /* eslint-disable no-shadow */
        return item.items
          .map((item) => {
            if (item.title === 'Other') {
              return `${item.textValue}`;
            }
            return item.title;
          })
          .join(', ');
        /* eslint-enable no-shadow */
      }
      return item.title;
    });
    return answers.join(', '); // eslint-disable-line consistent-return
  };

  render() {
    const { list } = this.state;
    return (
      <List>
        {list.length ? (
          list.map((item) => (
            <ListItem key={item.id}>
              <ListItemText primary={item.title} secondary={this.getAsnswer(item.answers)} />
            </ListItem>
          ))
        ) : (
          <EmptyBlock
            title="Customer has not completed on boarding questionnaire."
            body="Answers to on boarding questions will appear here once they are completed by the customer in their profile."
          />
        )}
      </List>
    );
  }
}

OnbordingQuestions.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default OnbordingQuestions;
