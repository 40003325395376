import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const styles = (theme) => {
  return {
    removeButton: {
      color: theme.palette.error.main,
    },
  };
};

const RemoveButton = withStyles(styles)((props) => {
  return (
    <Button color="inherit" className={props.classes.removeButton} onClick={props.deleteExercise}>
      DELETE
    </Button>
  );
});

export default RemoveButton;
