import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  progress: {
    width: '50px !important',
    height: '50px !important',
    color: 'white',
  },
  progressLinear: {
    width: '300px !important',
    height: '50px !important',
    color: 'white',
  },
  background: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '10000',
    position: 'fixed',
    touchAction: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Loading = (props) => {
  const { classes, open, children } = props;
  if (!open) {
    return null;
  }
  return (
    <div className={classes.background}>
      {children ? (
        <div className={classes.progressLinear}>{children}</div>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </div>
  );
};

export default withStyles(styles)(Loading);
