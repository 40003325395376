import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SuperTokens from 'supertokens-auth-react';
import Dashboard from './modules/Dashboard/Dashboard';
import CreatePassword from './modules/OutsidePages/CreatePassword/CreatePassword';
import Logout from './modules/Logout/Logout';
import Profile from './modules/Admins/Profile/Profile';
import ForgotPassword from './modules/OutsidePages/ForgotPassword/ForgotPassword';
import ResetPassword from './modules/OutsidePages/ResetPassword/ResetPassword';

export default [
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/profile" component={Profile} />,
  <Route exact path="/sessions" render={() => <Redirect to="/" />} />,
  <Route exact path="/invitation" component={CreatePassword} noLayout />,
  <Route exact path="/forgot" component={ForgotPassword} noLayout />,
  <Route exact path="/reset" component={ResetPassword} noLayout />,
  <Route exact path="/logout" component={Logout} noLayout />,
  <Route exact path="/auth" render={() => SuperTokens.getRoutingComponent()} noLayout />,
];
