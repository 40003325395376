import React, { PureComponent } from 'react';

import { SaveButton, Toolbar, crudUpdate, crudDelete, DeleteButton } from 'react-admin';

import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import uploadService from '../../../utils/upload';
import uploadVideoService from '../../../utils/uploadVideo';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import RemoveButton from '../../../components/RemoveButton/RemoveButton';
import Loading from '../../../components/Loading/Loading';
import countUploading from '../../../utils/countUploading';

const saveExercise = (id, values, prevValues, basePath) => {
  return crudUpdate('exercise', id, values, prevValues, basePath, '/exercise');
};

const deleteExercise = (resource, record, basePath, redirect) => {
  return crudDelete(resource, record.id, record, basePath, redirect);
};

class ExercisesUpdateToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      progressImg: 0,
      progressCueImgs: 0,
      progressVideo: 0,
      progressFullVideo: 0,
    };
  }

  progressCountImg = (progressEvent) => {
    this.setState({ progressImg: countUploading(progressEvent) });
  };

  progressCountVideo = (progressEvent) => {
    this.setState({ progressVideo: countUploading(progressEvent) });
  };

  progressCountFullVideo = (progressEvent) => {
    this.setState({ progressFullVideo: countUploading(progressEvent) });
  };

  progressCountCueImgs = (progressEvent) => {
    this.setState({ progressCueImgs: countUploading(progressEvent) });
  };

  uploadImg = (img) => {
    if (img && typeof img === 'object') {
      const formData = new FormData();
      formData.append('file', img.rawFile);
      return uploadService(formData, this.progressCountImg);
    }
    return Promise.resolve({ data: { fileName: img } });
  };

  uploadCueImage = (img) => {
    if (img && typeof img.picture === 'object') {
      const formData = new FormData();
      formData.append('file', img.picture.rawFile);
      return uploadService(formData, this.progressCountCueImgs).then((res) => {
        return Promise.resolve({
          picture: res.data.fileName,
          description: img.description,
        });
      });
    }

    return Promise.resolve(img);
  };

  uploadCueImages = (cueImages) => {
    const damp = [...cueImages].map((req) => this.uploadCueImage(req));
    return Promise.all(damp).then((data) => {
      const images = data.map((img) => {
        return img;
      });
      return Promise.resolve(images);
    });
  };

  uploadVideo = (video) => {
    if (video && video.rawFile) {
      return uploadVideoService(video, this.progressCountVideo);
    }
    return Promise.resolve(null);
  };

  uploadFullVideo = (video) => {
    if (video && video.rawFile) {
      return uploadVideoService(video, this.progressCountFullVideo);
    }
    return Promise.resolve(null);
  };

  getTips = (values) => {
    // eslint-disable-next-line no-array-constructor
    const tips = new Array(values.tip1, values.tip2, values.tip3);
    // eslint-disable-next-line no-param-reassign
    values.tips = [...tips.filter((t) => t)];
    return values;
  };

  handleClick = () => {
    // eslint-disable-next-line no-shadow
    const { basePath, handleSubmit, saveExercise } = this.props;
    return handleSubmit((values) => {
      // eslint-disable-next-line prefer-const,no-unused-vars
      let { video, fullVideo, newFullVideo, newVideo, ...cacheValues } = {
        ...values,
      };
      this.setState({ loading: true });
      if (!newFullVideo) {
        cacheValues.fullVideoId = newFullVideo;
      }
      Promise.all([
        this.uploadImg(cacheValues.picture),
        this.uploadVideo(newVideo),
        this.uploadFullVideo(newFullVideo),
        this.uploadCueImages(cacheValues.cueImages),
      ])
        .then((res) => {
          cacheValues.picture = res[0].data.fileName;
          if (res[1]) {
            cacheValues.videoId = res[1].id;
          }
          if (res[2]) {
            cacheValues.fullVideoId = res[2].id;
          }
          // eslint-disable-next-line prefer-destructuring
          cacheValues.cueImages = res[3];
          cacheValues = this.getTips(cacheValues);
          cacheValues.countdownTimer = 3;
          saveExercise(cacheValues.id, cacheValues, null, basePath);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  deleteExercise = () => {
    this.setState({ isOpen: true });
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  renderLoading = () => {
    const { progressImg, progressVideo, progressFullVideo, progressCueImgs } = this.state;
    if (!progressImg && !progressVideo && !progressFullVideo && !progressCueImgs) {
      return <Loading open={this.state.loading} />;
    }
    return (
      <Loading open={this.state.loading}>
        <>
          {progressImg ? (
            <>
              <p>Uploading Exercise Photo {progressImg} %</p>
              <LinearProgress value={progressImg} variant="determinate" />
            </>
          ) : null}
          {progressVideo ? (
            <>
              <p>Uploading Demo Video {progressVideo} %</p>
              <LinearProgress value={progressVideo} variant="determinate" />
            </>
          ) : null}
          {progressFullVideo ? (
            <>
              <p>Uploading Tutorial Video {progressFullVideo} %</p>
              <LinearProgress value={progressFullVideo} variant="determinate" />
            </>
          ) : null}
          {progressCueImgs ? (
            <>
              <p>Uploading Form Cue Images {progressCueImgs} %</p>
              <LinearProgress value={progressCueImgs} variant="determinate" />
            </>
          ) : null}
        </>
      </Loading>
    );
  };

  render() {
    /* eslint-disable no-unused-vars, no-shadow */
    const {
      handleSubmitWithRedirect,
      saveExercise,
      deleteExercise,
      handleSubmit,
      basePath,
      ...props
    } = this.props;
    /* eslint-enable no-unused-vars, no-shadow */

    return (
      <>
        {this.renderLoading()}
        <Toolbar
          {...props}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: 'none',
          }}
        >
          <SaveButton
            redirect="exercise"
            handleSubmitWithRedirect={this.handleClick}
            handleSubmit={handleSubmit}
            {...props}
            submitOnEnter={false}
          />
          <RemoveButton variant="contained" deleteExercise={this.deleteExercise} />
        </Toolbar>
        <DeleteAlertDialog
          title="Delete Exercise"
          subtitle="Are you sure you want to delete  ?"
          description="Exercise will be deleted permanently and removed from all the programs where it appears."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            redirect="/exercise"
            basePath={this.props.basePath}
            record={this.props.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

const ExercisesUpdateToolbarConnect = connect(undefined, {
  saveExercise,
  deleteExercise,
})(ExercisesUpdateToolbar);

export default ExercisesUpdateToolbarConnect;
