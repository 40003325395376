import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = () => ({
  emptyBlock: {
    padding: '25px 0',
  },
});

const EmptyBlock = (props) => {
  const { classes, title, body } = props;
  return (
    <div className={classes.emptyBlock}>
      <Typography align="center" variant="title">
        {title}
      </Typography>
      <Typography align="center" variant="body2">
        {body}
      </Typography>
    </div>
  );
};

EmptyBlock.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default withStyles(styles)(EmptyBlock);
