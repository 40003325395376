import React, { memo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { SaveButton, SelectInput, SimpleForm, Toolbar, UPDATE, refreshView } from 'react-admin';
import dataProvider from '../../../providers/dataProvider';
import { paymentStatusAvailableSelectOptions } from '../../../constants/paymentStatus';
import Loading from '../../../components/Loading/Loading';
import DialogTitleWithCloseIcon from '../../../components/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const CustomToolBar = (props) => {
  return (
    <Toolbar
      {...props}
      style={{
        background: 'none',
      }}
    >
      <SaveButton />
    </Toolbar>
  );
};

const UpdatePaymentStatusModal = ({ isOpen, selectedData, handleClose, refreshUser }) => {
  const [isLoading, changeLoading] = useState(false);

  const updateStatus = useCallback(
    async (values) => {
      changeLoading(true);
      await dataProvider(UPDATE, 'admin/users/product-status', {
        id: values.id,
        data: { status: values.paymentStatus },
      });
      await refreshUser();
    },
    [refreshUser],
  );

  return (
    <>
      <Dialog maxWidth="md" style={{ textAlign: 'center' }} open={isOpen} onClose={handleClose}>
        <Loading open={isLoading} />
        <DialogTitleWithCloseIcon onClose={handleClose}>
          Update Payment Type
        </DialogTitleWithCloseIcon>
        <DialogContent style={{ textAlign: 'left' }}>
          <SimpleForm
            record={selectedData}
            submitOnEnter={false}
            toolbar={<CustomToolBar />}
            save={updateStatus}
          >
            <SelectInput
              source="paymentStatus"
              label="Payment Status"
              choices={paymentStatusAvailableSelectOptions(selectedData.paymentType)}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(connect(null, { refreshUser: refreshView })(UpdatePaymentStatusModal));
