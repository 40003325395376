import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';

class SessionByProgramChart extends Component {
  getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  convertData = () => {
    const { data } = this.props;
    return data.map((item) => ({
      name: item.name,
      sessions: item.count,
    }));
  };

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={this.convertData()}
            dataKey="sessions"
            outerRadius={100}
            fill={this.getRandomColor()}
            label
          >
            {this.convertData().map((entry, index) => (
              <Cell key={index} fill={this.getRandomColor()} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
SessionByProgramChart.propTypes = {
  data: PropTypes.array.isRequired,
};
export default SessionByProgramChart;
