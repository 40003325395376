const paymentType = {
  oneTimePayment: { id: 0, name: 'One Time Payment' },
  paymentPlan: { id: 1, name: 'Payment Plan' },
  subscription: { id: 2, name: 'Subscription' },
};

export const paymentTypeById = Object.values(paymentType).reduce((acc, i) => {
  return { ...acc, [i.id]: i.name };
}, {});

export const paymentTypeSelectOptions = Object.values(paymentType).map((i) => ({
  id: i.id,
  name: i.name,
}));

export default paymentType;
