import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = {
  wrapper: {
    boxShadow: 'none',
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: '10px',
    height: '40px',
    width: '40px',
    cursor: 'pointer',
  },
  fio: {
    position: 'relative',
  },
};

class AppBarCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProfile = () => {
    this.props.history.push('/profile');
    this.handleClose();
  };

  handleLogOut = () => {
    localStorage.removeItem('user');
    this.handleClose();
    this.props.history.push('/logout');
  };

  getAvatarUrl = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.picture;
  };

  render() {
    const user = localStorage.getItem('user');
    if (!user) {
      return null;
    }
    const { firstName = 'Unknown', lastName = 'Unknown', role = 'Unknown' } = JSON.parse(user);
    const { classes } = this.props;
    return (
      <AppBar className={classes.wrapper}>
        <Toolbar>
          <Typography component="h2" variant="headline" color="inherit">
            GMB
          </Typography>
          <Avatar
            src={this.getAvatarUrl()}
            className={classes.avatar}
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            onClick={this.handleOpen}
            aria-haspopup="true"
          />
          <div className={classes.fio}>
            <Typography component="h6" variant="subheading" color="inherit">
              {firstName} {lastName}
            </Typography>
            <Typography component="h6" variant="subheading" color="inherit">
              {role}
            </Typography>
          </div>
        </Toolbar>
        <Menu
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleProfile}>My Profile</MenuItem>
          <MenuItem onClick={this.handleLogOut}>Logout</MenuItem>
        </Menu>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withRouter(AppBarCustom));
