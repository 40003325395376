import React, { PureComponent } from 'react';
import { GET_MANY } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import dataProvider from '../../../providers/dataProvider';

class StoriesInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: new Array(4).fill({ author: '', text: '' }),
    };
  }

  componentDidMount() {
    dataProvider(GET_MANY, `programs/${this.props.value.id}/stories`)
      .then((res) => {
        const list = [...this.state.list];
        const newList = list.map((val, i) => res.data[i] || { author: '', text: '' });
        this.setState({ list: newList });
        this.props.handleData(newList);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }

  handleChange = (event, i, name) => {
    const newList = [...this.state.list];
    newList[i][name] = event.target.value;
    this.setState({
      list: [...newList],
    });
    this.props.handleData(this.state.list);
  };

  renderList = () => {
    return this.state.list.map((item, i) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container spacing={24} key={i}>
          <Grid item xs={4}>
            <TextField
              name={`author${i}`}
              placeholder="author"
              label="author"
              value={item.author}
              fullWidth
              onChange={(event) => this.handleChange(event, i, 'author')}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              name={`text${i}`}
              label="text"
              placeholder="text"
              fullWidth
              value={item.text}
              onChange={(event) => this.handleChange(event, i, 'text')}
            />
          </Grid>
        </Grid>
      );
    });
  };

  render() {
    return (
      <div>
        <br />
        {this.renderList()}
      </div>
    );
  }
}

export default StoriesInput;
