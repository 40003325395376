import axios from 'axios';
import customDataProvider from './customDataProvider';

const httpClient = (url, options = {}) => {
  return axios({
    method: options.method,
    url,
    params: options.urlParams ? options.urlParams : null,
    onUploadProgress: options.onUploadProgress || null,
    data: options.body,
  }).catch((err) => {
    if (err.response.data.statusCode === 400 && Array.isArray(err.response.data.message)) {
      const error = err.response.data.message.reduce((acc, item) => {
        return `${acc}  ${item.field}: ${item.errors?.join(', ')} \n`;
      }, '');
      return Promise.reject(error);
    }
    if (err.response.data.statusCode === 400 && typeof err.response.data.message === 'string') {
      return Promise.reject(err.response.data.message);
    }
    if (err.response.data.statusCode === 401) {
      window.location.href = '/login';
      return Promise.resolve();
    }
    return Promise.reject(err);
  });
};

const dataProvider = customDataProvider(process.env.REACT_APP_API_URL, httpClient);

export default dataProvider;
