import React from 'react';

const ImgField = (props) => {
  if (!props.record?.picture) {
    return null;
  }
  if (props.record.rawFile) {
    return <img width="100px" src={props.record.picture} alt="" />;
  }
  return (
    <img
      style={{ padding: '5px' }}
      src={`${process.env.REACT_APP_IMAGES_FILE_HOST}/fit-in/${props.width || 50}x${
        props.height || 50
      }/${props.record.picture}`}
      alt=""
    />
  );
};

export default ImgField;
