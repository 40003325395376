// eslint-disable-next-line max-classes-per-file
import React, { PureComponent } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  LongTextInput,
  BooleanInput,
  SaveButton,
  Toolbar,
  crudCreate,
  required,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import uploadService from '../../../utils/upload';
import uploadVideoService from '../../../utils/uploadVideo';
import Loading from '../../../components/Loading/Loading';
import countUploading from '../../../utils/countUploading';

const saveExercise = (values, basePath) => {
  return crudCreate('exercise', values, basePath, '/exercise');
};

class ExercisesCreateToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      progressImg: 0,
      progressVideo: 0,
    };
  }

  uploadImg = (img) => {
    const formData = new FormData();
    formData.append('file', img.rawFile);
    return uploadService(formData, this.progressCountImg);
  };

  uploadVideo = (video) => {
    return uploadVideoService(video, this.progressCountVideo);
  };

  progressCountImg = (progressEvent) => {
    this.setState({ progressImg: countUploading(progressEvent) });
  };

  progressCountVideo = (progressEvent) => {
    this.setState({ progressVideo: countUploading(progressEvent) });
  };

  handleClick = () => {
    // eslint-disable-next-line no-shadow
    const { basePath, handleSubmit, redirect, saveExercise } = this.props;
    return handleSubmit((values) => {
      this.setState({ loading: true });
      Promise.all([this.uploadImg(values.picture), this.uploadVideo(values.file)])
        .then((res) => {
          saveExercise(
            {
              ...values,
              type: values.type ? 1 : 2,
              picture: res[0].data.fileName,
              videoId: res[1].id,
            },
            basePath,
            redirect,
          );
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    // eslint-disable-next-line no-unused-vars, no-shadow
    const { handleSubmitWithRedirect, saveExercise, ...props } = this.props;
    const { progressImg, progressVideo } = this.state;
    return (
      <>
        <Loading open={this.state.loading}>
          <>
            <p>Uploading Exercise Photo {progressImg} %</p>
            <LinearProgress value={progressImg} variant="determinate" />
            <p>Uploading Demo video {progressVideo} %</p>
            <LinearProgress value={progressVideo} variant="determinate" />
          </>
        </Loading>
        <Toolbar {...props}>
          <SaveButton
            redirect="exercise"
            saving
            handleSubmitWithRedirect={this.handleClick}
            {...props}
            submitOnEnter={false}
          />
        </Toolbar>
      </>
    );
  }
}

const ExercisesCreateToolbarConnect = connect(undefined, { saveExercise })(ExercisesCreateToolbar);

class ExercisesCreate extends PureComponent {
  handleSubmit = (func) => {
    return func;
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <>
        <Create {...props}>
          <SimpleForm toolbar={<ExercisesCreateToolbarConnect />}>
            <TextInput label="Exercise Name" source="name" validate={required()} />
            <ImageInput
              formClassName={classes.inlineBlock}
              source="picture"
              label="Exercise Photo"
              validate={required()}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <FileInput
              source="file"
              label="Demo video"
              accept="video/mp4"
              validate={required()}
              formClassName={classes.inlineBlock}
            >
              <FileField source="src" title="title" />
            </FileInput>
            <LongTextInput source="description" label="Description" validate={required()} />
            <BooleanInput
              label="Ask user to switch sides and repeat exercise"
              source="forEachSide"
              defaultValue={false}
            />
            <BooleanInput
              label="Use repetition counter instead of timer"
              source="type"
              defaultValue={false}
            />
            <BooleanInput
              label="Hide done button in timer"
              source="doneButton"
              defaultValue={false}
            />
          </SimpleForm>
        </Create>
      </>
    );
  }
}

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

export default withStyles(styles)(ExercisesCreate);
