import React, { PureComponent } from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  SelectField,
  FileField,
  UrlField,
  ReferenceManyField,
  Datagrid,
  BooleanField,
  FunctionField,
  ShowController,
} from 'react-admin';
import ImgField from '../../../components/ImgField/ImgField';

import equipment from '../../../constants/equipment';
import difficulty from '../../../constants/difficulty';
import ProgramTrack from './ProgramTrack/ProgramTrack';

class ShowProgram extends PureComponent {
  render() {
    return (
      <ShowController {...this.props}>
        {(controllerProps) => {
          const isExternalProgram = controllerProps?.record?.isExternal;
          return (
            <Show {...this.props} {...controllerProps}>
              <TabbedShowLayout>
                <Tab label="Main information">
                  <ImgField source="picture" title="Picture" addLabel width="100" height="100" />
                  <TextField source="name" />
                  <BooleanField source="isExternal" />
                  {isExternalProgram && <BooleanField source="isAlphaPosse" />}
                  <RichTextField source="description" />
                  {isExternalProgram && <TextField source="externalLink" />}
                  {!isExternalProgram && <SelectField source="equipment" choices={equipment} />}
                  {!isExternalProgram && <SelectField source="complexity" choices={difficulty} />}
                  {!isExternalProgram && (
                    <FileField
                      target="_blank"
                      source="introVideo.muxHlsUrl"
                      title="introVideo.originalName"
                      label="Video"
                      addLabel
                    />
                  )}
                </Tab>
                {!isExternalProgram && (
                  <Tab label="Buy Program">
                    <h4>Bullet Points</h4>
                    <TextField source="bullets.0" label="Bullet" />
                    <TextField source="bullets.1" label="Bullet" />
                    <TextField source="bullets.2" label="Bullet" />
                    <TextField source="bullets.3" label="Bullet" />
                    <hr />
                    <h4>GMB Stories</h4>
                    <UrlField source="storiesUrl" />
                    <ReferenceManyField addLabel={false} reference="programs" target="stories">
                      <Datagrid>
                        <TextField source="author" />
                        <TextField source="text" />
                      </Datagrid>
                    </ReferenceManyField>
                  </Tab>
                )}
                {!isExternalProgram && (
                  <Tab label="Build Program">
                    <h4>Introduction Screens</h4>
                    <TextField source="introScreens.0.title" label="Title" />
                    <TextField source="introScreens.0.text" label="Text" />
                    <FileField
                      target="_blank"
                      source="introScreens.1.video.muxHlsUrl"
                      title="introScreens.1.video.originalName"
                      label="Video"
                      addLabel
                    />
                    <TextField source="introScreens.1.title" label="Title" />
                    <TextField source="introScreens.1.text" label="Text" />
                  </Tab>
                )}
                {!isExternalProgram && (
                  <Tab label="Sessions">
                    <ReferenceManyField addLabel={false} reference="programs" target="sessions">
                      <Datagrid>
                        <TextField source="id" />
                        <TextField source="name" />
                        <FunctionField
                          label="Duration options"
                          render={(record) => {
                            return record.durationOptions
                              ? Object.keys(record.durationOptions).join(',')
                              : null;
                          }}
                        />
                        <BooleanField source="isBasic" />
                        <TextField source="description" />
                      </Datagrid>
                    </ReferenceManyField>
                  </Tab>
                )}
                {!isExternalProgram && (
                  <Tab label="Tracks">
                    <ProgramTrack />
                  </Tab>
                )}
              </TabbedShowLayout>
            </Show>
          );
        }}
      </ShowController>
    );
  }
}

export default ShowProgram;
