import React, { memo, useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  temp: {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  },
};

const itemType = 'item';

const EditPageItem = ({ item: itemOuter, changeArray, moveCard, id, index, classes }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: itemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();
      changeArray((prev) =>
        prev
          .filter((prevItem) => prevItem.productId !== itemOuter.productId)
          .map((a, indexInner) => ({ ...a, order: indexInner + 1 })),
      );
    },
    [itemOuter.productId, changeArray],
  );

  return (
    <div ref={ref} className={classes.temp} style={{ opacity }} data-handler-id={handlerId}>
      {itemOuter.order} {itemOuter.name}
      <IconButton aria-label="Delete" onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default memo(withStyles(styles)(EditPageItem));
