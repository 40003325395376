import React, { PureComponent } from 'react';
import {
  List,
  TextField,
  Datagrid,
  FunctionField,
  BooleanField,
  SelectField,
  CREATE,
  crudGetList,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import difficulty from '../../../constants/difficulty';
import roles from '../../../constants/roles';
import dataProvider from '../../../providers/dataProvider';

const styles = {
  center: {
    textAlign: 'center',
  },
  nameCol: {},
  action: {
    textAlign: 'center',
  },
};

class ListPrograms extends PureComponent {
  handleAction = (action, record) => {
    if (action && action.toLowerCase() === 'edit') {
      this.props.history.push(`/programs/${record.id}`);
    }
    if (action && action.toLowerCase() === 'duplicate') {
      dataProvider(CREATE, `programs/${record.id}/duplicate`, {}).then(() => {
        this.props.crudGetList(
          'programs',
          { page: 1, perPage: 10 },
          { field: 'createdAt', order: 'DESC' },
          { order_by: '-createdAt' },
        );
      });
    }
  };

  render() {
    const { classes, permissions, ...props } = this.props;
    return (
      <List
        {...props}
        exporter={false}
        bulkActions={false}
        sort={{}}
        filterDefaultValues={{ order_by: '-createdAt' }}
        filter={{}}
      >
        <Datagrid rowClick="show">
          <TextField
            label="Program Name"
            source="name"
            sortable={false}
            className={classes.nameCol}
          />
          <FunctionField
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
            label="Number of Sessions / Is External"
            render={(record) => (record.isExternal ? 'Is External' : record.sessionsCount)}
          />
          <SelectField
            sortable={false}
            className={classes.center}
            choices={difficulty}
            headerClassName={classes.center}
            label="Type"
            source="complexity"
          />
          <BooleanField
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
            source="isPublic"
          />
          {permissions === roles.superAdmin ? (
            <FunctionField
              cellClassName={classes.action}
              label=""
              onClick={(e) => {
                e.stopPropagation();
              }}
              render={(record) => (
                <DotsMenu
                  onChange={(e) => this.handleAction(e, record)}
                  options={['edit', 'duplicate']}
                />
              )}
            />
          ) : null}
        </Datagrid>
      </List>
    );
  }
}

export default withStyles(styles)(connect(null, { crudGetList })(ListPrograms));
