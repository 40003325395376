import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TextInput, required, CREATE, SimpleForm } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { validatePassword } from '../../../utils/validators';
import dataProvider from '../../../providers/dataProvider';
import ToolbarResetPassword from './ToolbarResetPassword';
import { styles } from '../styles';

const style = (theme) => {
  return {
    ...styles,
    icon: {
      backgroundColor: theme.palette.secondary.main,
    },
    hint: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.grey[500],
    },
    form: {
      textAlign: 'center',
    },
    button: {
      display: 'block',
      margin: '20px auto 0px auto',
    },
    errors: {
      color: theme.palette.error.main,
    },
  };
};

const validateConfirmPassword = (value, allValues) => {
  if (allValues.password !== allValues.passwordConfirm) {
    return 'The password shoud be equal "password"';
  }
  return undefined;
};

const validatePasswordPattern = (value) => {
  if (validatePassword(value)) {
    return validatePassword(value);
  }
  return undefined;
};

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      error: [],
    };
  }

  componentDidMount() {
    this.setState({ code: this.getJsonFromUrl().reset_code });
  }

  getJsonFromUrl = (url) => {
    // eslint-disable-next-line no-param-reassign
    if (!url) url = this.props.location.hash;

    const query = url.substr(1);
    const result = {};
    // eslint-disable-next-line func-names
    query.split('&').forEach(function (part) {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  };

  save = (data) => {
    dataProvider(CREATE, 'account/reset_confirm', {
      data: { ...data, resetCode: this.state.code },
    })
      .then(() => {
        this.props.history.push('/login');
      })
      .catch((err) => {
        if (typeof err === 'object') {
          const errors = err
            .map((item) => {
              return item.errors.map((val) => val);
            })
            .reduce((acc, val) => acc.concat(val), []);
          this.setState({ error: errors });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className={classes.logo} src="./img/logo.svg" />
          </div>
          <br />
          <>
            <Typography variant="headline">RESET PASSWORD</Typography>
            <Typography component="h5" align="center">
              Welcome back! You can set up a new password for your account below:
            </Typography>
            <SimpleForm
              className={classes.form}
              save={this.save}
              toolbar={<ToolbarResetPassword />}
            >
              <TextInput
                fullWidth
                type="password"
                source="password"
                label="New Password"
                validate={[required(), validatePasswordPattern]}
              />
              <TextInput
                fullWidth
                type="password"
                source="passwordConfirm"
                label="Confirm New Password"
                validate={[required(), validateConfirmPassword]}
              />
              <>
                <div className={classes.errors}>
                  {error.map((err, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i}>{err}</div>
                  ))}
                </div>
              </>
            </SimpleForm>
          </>
        </Card>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(ResetPassword));
